import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allRecords: [],
  allLikes: [],
  msg: '',
  success: false,
};

export const recognizingSlice = createSlice({
  name: 'recognizing',
  initialState,
  reducers: {
    setRecognizing: (state, action) => {
      state.allRecords = action.payload;
    },
    mergeRecognizing: (state, action) => {
      const newRecords = action.payload.data;

      // Create a copy of the existing records array
      const existingRecords = [...state.allRecords];

      // Loop through each new record
      newRecords.forEach(newRecord => {
        // Find the index of the existing record with the same RecId as the new record
        const existingRecordIndex = existingRecords.findIndex(
          existingRecord => existingRecord.RecId === newRecord.RecId,
        );

        // If an existing record with the same RecId is found, replace it with the new record
        if (existingRecordIndex !== -1) {
          existingRecords.splice(existingRecordIndex, 1, newRecord);
        }
        // Otherwise, add the new record to the end of the array
        else {
          existingRecords.push(newRecord);
        }
      });

      // Update the state with the merged records
      state.allRecords = existingRecords;
    },
    updateRecord: (state, action) => {
      const { recId, data } = action.payload;

      // Find the index of the record with the matching RecId
      const recordIndex = state.allRecords.findIndex(record => record.RecId === recId);

      // If a matching record is found, update its data
      if (recordIndex !== -1) {
        state.allRecords[recordIndex] = {
          ...state.allRecords[recordIndex],
          ...data,
        };
      }
    },
    deleteRecord: (state, action) => {
      const recIdToDelete = action.payload.recId;

      // Filter out the record with the matching RecId
      state.allRecords = state.allRecords.filter(record => record.RecId !== recIdToDelete);
    },

    setLikes: (state, action) => {
      state.allLikes = action.payload;
    },
    setStatus: (state, action) => {
      state.success = action.payload.success;
      state.msg = action.payload.msg;
    },
    resetStatus: state => {
      state.success = false;
      state.msg = '';
    },
  },
});
