import { staffSlice } from './staffSlice';
import staffService from '../../services/staffServices';
const enums = require('../../enums');

export const sliceActions = staffSlice.actions;

export const fetchAllStaffs = (scope = 'basic') => {
  return async dispatch => {
    let allStaffs = await staffService.getAllStaffs(scope);
    dispatch(sliceActions.setStaff(allStaffs));
  };
};

export const fetchSeatMap = () => {
  return async dispatch => {
    let result = await staffService.getSeatMap();
    dispatch(sliceActions.setSeatMap(result));
  };
};

export const fetchWFOList = () => {
  return async dispatch => {
    let result = await staffService.getWFOList();
    console.log(result);
    dispatch(sliceActions.setWFO(result));
  };
};

export const updateSeatByStaff = (office, col, row, day, staffId, staffName) => {
  return async dispatch => {
    let response = await staffService.updateSeatCard(office, col, row, day, staffId);

    dispatch(
      sliceActions.updateSeatByStaff({
        office,
        col,
        row,
        day,
        staffId,
        staffName,
      }),
    );
    window.message.notification(
      response?.msg || '',
      response?.success ? enums.notificationType.S : enums.notificationType.W,
    );
  };
};

export const fetchWFO = () => {
  return async dispatch => {
    let allStaffs = await staffService.getWFOList();
    dispatch(sliceActions.setWFO(allStaffs));
  };
};

export const fetchDOBList = () => {
  return async dispatch => {
    let records = await staffService.getBirthdayListByMonth();
    dispatch(sliceActions.setDOB(records));
  };
};
