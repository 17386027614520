import React, { useEffect, useState } from 'react';
import moment from 'moment';
import enums from '../../enums';
import PageSpinner from '../../components/PageSpinner';
import officeService from '../../services/officeService';
import timecardService from '../../services/timecardService';
import { FcClock } from 'react-icons/fc';
import styles from './ClockIn.module.scss';
const helper = require('../../utils/helper');

let homeTimer;
export const ClockInOut = ({ lng, lat, initialLocation, distance }) => {
  const [officeList, setOfficeList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [lastClock, setLastClock] = useState(null);
  const [location, setLocation] = useState(initialLocation);
  const [remarks, setRemarks] = useState('');

  useEffect(() => {
    loadData();

    homeTimer = setInterval(() => {
      if (document.getElementById('datetime')) {
        document.getElementById('datetime').value = moment(new Date()).format('DD/MM/YYYY hh:mm:ss A');
      } else {
        clearInterval(homeTimer);
      }
    }, 500);
  }, [distance]);

  const loadData = React.useCallback(async () => {
    const last = await timecardService.getLastList();
    if (last.length) {
      setLastClock({
        Type: last[0].Type,
        DateTime: last[0].Date + ' ' + last[0].Time,
      });
    }

    const office = await officeService.getOfficeList();
    const distKM = parseInt(distance);

    setOfficeList(
      distKM > 2
        ? office.filter(o => !['11A', '11E'].includes(o.value))
        : office.filter(o => !['Client'].includes(o.value)),
    );
  }, [distance]);

  const handleButtonClicked = async e => {
    let createStatus = {};
    let packValues = {
      ClockType: '', //this.state.location === this.state.qrLocation ? 'QR' :
      Remarks: remarks,
      MITI_Location: location,
      Type: 1,
      lat: lat,
      lng: lng,
    };

    setLoading(true);
    if (e?.target) {
      if (e.target.getAttribute('data-type') === 'clock-in') {
        // click in
        packValues.Type = 1;
        createStatus = await timecardService.createRecord(packValues);
      } else {
        // clock out
        packValues.Type = 2;
        createStatus = await timecardService.createRecord(packValues);
      }
    } else {
      packValues.Type = parseInt(e) || 1;
      createStatus = await timecardService.createRecord(packValues);
    }

    window.message.notification(
      createStatus.msg,
      createStatus.success ? enums.notificationType.S : enums.notificationType.W,
    );

    if (createStatus.success) {
      alert(
        `Timecard saved!\n=====================\nLocation: ${location}\nType: ${
          packValues.Type === 1 ? 'Clock-in' : 'Clock-out'
        }`,
      );

      packValues.DateTime = helper.ISODateTime(new Date());
      localStorage.setItem('lastClock', JSON.stringify(packValues));
      setRemarks('');
      setLastClock(packValues);
      setLoading(false);
    } else {
      alert('Error, please try again. ' + createStatus.msg);
    }
  };

  return (
    <>
      {isLoading && <PageSpinner />}
      <div className={styles['attendance-card']}>
        <div className={styles['fieldSet']}>
          <div className={styles['fieldValue']}>
            <div className={styles['field-datetime']}>
              <FcClock size={28} />
              <input
                id="datetime"
                className={styles['datetime']}
                type="datetime"
                value={moment(new Date()).format('DD/MM/YYYY hh:mm:ss A')}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className={styles['fieldSet']}>
          <div className={styles['fieldLabel']}>{localStorage.getItem('UserId')}</div>
          <div className={styles['fieldValue']}>
            <div className={styles['field-name']}>{localStorage.getItem('UserName')}</div>
          </div>
        </div>
        <div className={styles['fieldSet']}>
          <div className={styles['fieldLabel']}>Location | {distance + ' from office'}</div>
          <div className={styles['fieldValue']}>
            <div className={styles['officeLocation']}>
              {officeList.map((loc, idx) => {
                return (
                  <label key={idx} className={`${location === loc.value ? 'checked' : ''}`}>
                    <input
                      type={'radio'}
                      value={loc.value}
                      checked={location === loc.value}
                      onChange={e => setLocation(e.target.value)}
                    />{' '}
                    {loc.item}
                  </label>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles['fieldSet']}>
          <div className={styles['fieldLabel']}>Remarks</div>
          <div className={styles['fieldValue']}>
            <textarea
              onChange={e => setRemarks(e.target.value)}
              value={remarks}
              placeholder="Note: No need to mention clock-in or clock-out, please use buttons below"
            />
          </div>
        </div>
        <div className={styles['fieldSet']}>
          <div className={styles['fieldLabel']}></div>
          <div className={styles['fieldValue']}>
            <div className={styles['btnGroup']}>
              <button className={styles['clockIn']} data-type="clock-in" onClick={handleButtonClicked}>
                Clock-In
              </button>
              <button className={styles['clockOut']} data-type="clock-out" onClick={handleButtonClicked}>
                Clock-Out
              </button>
            </div>
          </div>
        </div>
        {lastClock && (
          <div className={styles['fieldSet']}>
            <div className={styles['fieldLabel']}>Last action</div>
            <div className={styles['fieldValue']}>
              {parseInt(lastClock.Type) === 1 ? 'Clock-In' : 'Clock-out'} @{' '}
              {moment(lastClock.DateTime).format('DD/MM/YYYY hh:mm:ss A')}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
