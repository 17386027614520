import { recognizingSlice } from './recognizingSlice';
import recognizingService from '../../services/recognizingService';
export const sliceActions = recognizingSlice.actions;

export const fetchRecents = type => {
  return async dispatch => {
    const results = await recognizingService.getRecents(type);
    dispatch(sliceActions.setRecognizing(results));
  };
};

export const fetchLikes = () => {
  return async dispatch => {
    const results = await recognizingService.getLikes();
    dispatch(sliceActions.setLikes(results));
  };
};

export const saveFeedback = payload => {
  return async dispatch => {
    let ret = await recognizingService.save(payload.staffId, payload.message, payload.isFeedback);
    if (ret.success) {
      payload.RecId = ret.lastId;
      dispatch(fetchRecents());
    }
    dispatch(
      sliceActions.setStatus({
        success: ret.success,
        msg: ret.msg,
      }),
    );
  };
};

export const deleteFeedback = recId => {
  return async dispatch => {
    let ret = await recognizingService.delete(recId);
    if (ret.success) {
      dispatch(sliceActions.deleteRecord({ recId: recId }));
    } else {
      dispatch(
        sliceActions.setStatus({
          success: ret.success,
          msg: ret.msg,
          actionType: 'delete',
        }),
      );
    }
  };
};

export const thumbAction = data => {
  return async dispatch => {
    let ret = await recognizingService.thumbUp(data.RecId, data.Value);
    if (ret.success) {
      dispatch(sliceActions.updateRecord({ recId: data.RecId, data }));
    } else {
      dispatch(
        sliceActions.setStatus({
          success: ret.success,
          msg: ret.msg,
          actionType: 'thumb',
        }),
      );
    }
  };
};

export const resetStatus = () => {
  return async dispatch => {
    dispatch(sliceActions.resetStatus());
  };
};
