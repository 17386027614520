//import axios from 'axios';
const helper = require('../../utils/helper');

const initWindowLog = () => {
  if (typeof window.UXLogSending === 'undefined') window.UXLogSending = false;

  if (typeof window.UXLogs === 'undefined') window.UXLogs = [];

  if (typeof window.UXLogsPending === 'undefined') window.UXLogsPending = [];
};

export const addUXLog = (data = null) => {
  // for mutiple
  initWindowLog();

  if (data !== null) {
    data = setData(data);

    if (window.UXLogSending === false) {
      if (window.UXLogsPending.length > 0) {
        // merge log
        window.UXLogs.concat(window.UXLogsPending);
      }
      // Push new log
      window.UXLogs.push(data);
    } else {
      window.UXLogsPending.push(data);
    }
  }
};

export const sendUXLogScheduler = () => {
  initWindowLog();

  // set for next call
  setTimeout(() => {
    sendUXLogScheduler();
  }, 10 * 1000); // schedule for every 10 mins, if log exists

  if (window.UXLogs.length > 0) {
    //window.UXLogSending === false &&
    window.UXLogSending = true;

    try {
      /*axios({
        url: helper.getAPIHost() + '/api/UXTracking',
        data: window.UXLogs,
        method: 'post',
        headers: helper.apiHeaders(),
      })
        .then(async res => {
          console.log('UX Tracking Batch - triggered', res);
          window.UXLogs = [];
          window.UXLogSending = false;
          helper.catchServer(res);
        })
        .catch(error => {
          console.error('UX Error: ', error);
          window.UXLogSending = false;
        });*/
    } catch (e) {
      console.log('UXLog error');
      window.UXLogSending = false;
    }
  }
};

export const sendUxLog = data => {
  // for single
  if (data === undefined || data === null) return;

  data = setData(data);

  let l_uxlogs = [];
  l_uxlogs.push(data);

  try {
    /*axios({
      url: helper.getAPIHost() + '/api/UXTracking',
      data: l_uxlogs,
      method: 'post',
      //headers: helper.apiHeaders(),
    })
      .then(async res => {
        console.log('UX Tracking - triggered', res);
        helper.catchServer(res);
      })
      .catch(error => {
        console.error('UX Error: ', error);
      });*/
  } catch (e) {
    console.log('UXLog error');
  }
};

export const setData = logData => {
  // to set common information
  let appServer = (localStorage.getItem('APP_SERVER') || '-') + ';';
  let webServer = (localStorage.getItem('WEB_SERVER') || '-') + ';';
  logData = {
    ...logData,
    userID: helper.getUserId(),
    host: window.location.hostname.toLowerCase(),
    createdDtm: helper.getISOLocalString(),
    createdBy: helper.getUserId(),
    webVersion: webServer + helper.getWebVersion(),
    appVersion: appServer + (localStorage.getItem('appVersion') || ''),
    userAgent: navigator.userAgent,
  };

  if (!logData.data) logData.data = '';

  return logData;
};

export const getPath = () => window.location.pathname || '';

// Customized Log Type --------------------------------------

// Log for logon
export const logAuthenticated = isSSO => {
  let data = {
    level: 1,
    screen: 'Logon',
    action: 'Logon' + (isSSO ? ' (SSO)' : ''),
    data: '',
  };
  console.log('Version: ' + helper.getWebVersion());
  sendUxLog(data); // Send Instantly
  return true;
};

// Log for logon
export const logExit = (last = false) => {
  let login = parseInt(localStorage.getItem('LoginTime') || 0);
  let curr = new Date().getTime();
  let usedTime = curr - login;
  let data = {
    level: 1,
    screen: 'Logout',
    action: 'Logout',
    data: (usedTime / 1000).toFixed(0),
  };
  if (!last) {
    sendUxLog(data); // Send Instantly
  } else {
    navigator.sendBeacon(helper.getAPIHost() + '/api/UXTracking', JSON.stringify(data));
  }
};

// Log for navigation
export const logScreen = (screen, action, extraData) => {
  if (!screen) return;

  action = action || 'click';
  let data = {
    level: 1,
    screen: screen,
    action: action,
    data: typeof extraData === 'object' ? JSON.stringify(extraData) : extraData,
  };

  addUXLog(data);
};

// Log for action
export const logAction = (screen, action, extraData) => {
  if (!screen || !action) return;

  action = action || 'click';
  let data = {
    level: 2,
    screen: screen,
    action: action,
    data: typeof extraData === 'object' ? JSON.stringify(extraData) : extraData,
  };
  addUXLog(data);
};

// Log for info
export const logInfo = (screen, action, info) => {
  if (!screen || !info) return;

  let data = {
    level: 3,
    screen: screen,
    action: action,
    data: typeof info === 'object' ? JSON.stringify(info) : info,
  };

  addUXLog(data);
};

// Log for errors
export const logError = (screen, action, errorMsg, extraData) => {
  if (!screen || !errorMsg) return;

  let data = {
    level: 4,
    screen: screen,
    action: action,
    error: errorMsg,
    data: typeof extraData === 'object' ? JSON.stringify(extraData) : extraData,
  };

  sendUxLog(data); // Send instantly
};

// Log for performance
export const logPerformance = (screen, action, timeUsed) => {
  if (!screen) return;

  let data = {
    level: 99,
    screen: screen,
    action: action,
    data: timeUsed + ' ms',
  };

  addUXLog(data);
};

// Log for Merchandise Insights Image not found
export const logMIImageNotFound = (screen, type, url) => {
  if (!screen) return;

  let data = {
    level: 98,
    screen: screen,
    action: 'No Image',
    data: type + ': ' + url,
  };

  addUXLog(data);
};
