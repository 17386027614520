import React, { useEffect, useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GiAchievement } from 'react-icons/gi';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import Select from 'react-select';
import classnames from 'classnames';
import { fetchAllStaffs } from '../../../store/staff/staffActions';
import { fetchRecents, fetchLikes, saveFeedback, resetStatus } from '../../../store/recognizing/recognizingActions';
import './styles.scss';
import enums from '../../../enums';
import Card from './Card';

const Recognizing = () => {
  const dispatch = useDispatch();
  const [rtStaff, rtRecognizing] = useSelector(s => [s.staff, s.recognizing]);

  const currentUser = localStorage.getItem('UserId');
  const [activeTab, setTab] = useState('1');
  const [showForm, setShowForm] = useState(false);
  const [recordType, setType] = useState(0);
  const [colleague, setColleague] = useState([]);
  const [message, setMessage] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [viewFeedback, setViewFeedback] = useState(false);

  useEffect(() => {
    if (rtStaff.allStaffs.length === 0) {
      dispatch(fetchAllStaffs());
    }

    if (rtRecognizing.allRecords.length === 0) {
      dispatch(fetchRecents());
      dispatch(fetchLikes());
    }
  }, []);

  useEffect(() => {
    if (rtStaff.allStaffs.length > 0) {
      let allStaffs = rtStaff.allStaffs;
      allStaffs = allStaffs.map(s => {
        return {
          value: s.StaffId,
          label: s.StaffName,
        };
      });
      allStaffs = allStaffs.sort((a, b) => a.label.localeCompare(b.label));
      setStaffList(allStaffs);
    }
  }, [rtStaff.allStaffs]);

  useEffect(() => {
    if (rtRecognizing.msg) {
      window.message.notification(
        rtRecognizing.msg,
        rtRecognizing.success ? enums.notificationType.S : enums.notificationType.W,
      );

      toggle();
      setColleague([]);
      setMessage('');
      setType(0);

      setTimeout(() => {
        dispatch(resetStatus());
      }, 1000);
    }
  }, [rtRecognizing.msg]);

  useEffect(() => {
    setTab('1');
  }, [viewFeedback]);

  const toggle = () => setShowForm(!showForm);
  const handleRadioChange = v => setType(v);
  const handleSelectChange = selectedOption => setColleague(selectedOption);
  const handleInputChange = e => setMessage(e.target.value);

  const handleSubmit = async e => {
    e.preventDefault();
    if (((recordType === 0 && colleague.length > 0) || recordType >= 1) && message) {
      const staffIds = colleague.map(s => {
        return s.value;
      });

      dispatch(
        saveFeedback({
          staffId: staffIds.join(',') || '',
          message: message,
          isFeedback: recordType,
        }),
      );
    } else {
      alert('Please select name & provide the story.');
    }
  };

  return (
    <>
      <div className="recognizing">
        <div className="title">
          <GiAchievement className="icon" size={28} />
          Shoutouts
          <div className="right">
            <div className="viewFeedback" onClick={() => setViewFeedback(!viewFeedback)}>
              {!viewFeedback && <MdCheckBoxOutlineBlank size={24} />}
              {viewFeedback && <MdCheckBox size={24} />}
              <span className="viewFeedbackTxt">
                View
                <br /> Feedback
              </span>
            </div>
            <Button className="button" onClick={() => setShowForm(true)}>
              Add new
            </Button>
          </div>
        </div>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === '1',
              })}
              onClick={() => {
                setTab('1');
              }}
            >
              {!viewFeedback ? 'Recents' : 'Feedback'}
            </NavLink>
            <NavLink
              className={classnames({
                active: activeTab === '2',
              })}
              onClick={() => {
                setTab('2');
              }}
            >
              {!viewFeedback ? 'Recognition' : 'Feedback'} I Gave
            </NavLink>
            {!viewFeedback && (
              <NavLink
                className={classnames({
                  active: activeTab === '3',
                })}
                onClick={() => {
                  setTab('3');
                }}
              >
                Recognition I Received
              </NavLink>
            )}
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="messageArea">
          <TabPane tabId="1">
            <Row>
              <Col>
                {rtRecognizing.allRecords
                  .filter(r => r.IsFeedback === (viewFeedback ? '1' : '0'))
                  .map(r => (
                    <Card key={r.RecId} data={r} />
                  ))}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col>
                {rtRecognizing.allRecords
                  .filter(r => r.IsFeedback === (viewFeedback ? '1' : '0') && r.MySelf === '1')
                  .map(r => (
                    <Card key={r.RecId} data={r} canDelete={true} />
                  ))}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                {rtRecognizing.allRecords
                  .filter(
                    r => r.IsFeedback === (viewFeedback ? '1' : '0') && r.ToUser?.split(',')?.includes(currentUser),
                  )
                  .map(r => (
                    <Card key={r.RecId} data={r} />
                  ))}
              </Col>
            </Row>
          </TabPane>
        </TabContent>

        <Modal isOpen={showForm} toggle={toggle}>
          <ModalHeader toggle={toggle}>Recognize Colleague</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Type</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="type"
                      value="Recognizing"
                      checked={recordType === 0}
                      onChange={() => handleRadioChange(0)}
                    />{' '}
                    Recognizing (Name is mandatory)
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="type"
                      value="Feedback"
                      checked={recordType === 1}
                      onChange={() => handleRadioChange(1)}
                    />{' '}
                    Public Feedback (Name is optional &amp; anonymous)
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="type"
                      value="Feedback-Private"
                      checked={recordType === 2}
                      onChange={() => handleRadioChange(2)}
                    />{' '}
                    Private Feedback (Name is optional &amp; anonymous)
                  </Label>
                </FormGroup>
              </FormGroup>

              <FormGroup>
                <Label for="name">Name</Label>
                <Select
                  id="colleague"
                  name="colleague"
                  options={staffList}
                  value={colleague}
                  onChange={handleSelectChange}
                  placeholder="Select colleague"
                  isMulti={true}
                  isClearable
                />
              </FormGroup>
              <FormGroup>
                <Label for="message">Message</Label>
                <Input
                  type="textarea"
                  name="message"
                  id="message"
                  className="recognizingMessageInput"
                  placeholder="Tell the story"
                  value={message}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default Recognizing;
