import React from 'react';
import AuthForm, { STATE_LOGIN, STATE_LOGOUT } from './authForm';
import { Col, Row } from 'reactstrap';
import bgimg from '../../assets/img/issLoginBG.png';
import pageLogo from '../../assets/img/logo/logo.png';
import './login.scss';

const helper = require('../../utils/helper');
const auth = require('./lib/authentication');

class LoginCenterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginProcess: false,
    };

    if (this.props.authState === STATE_LOGOUT) {
      auth.resetUserData();
    }
  }

  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  componentDidMount = () => {
    //auth.resetUserData();
    if (auth.isAuthenticated()) this.props.history.push('/home');
    window.onbeforeunload = undefined;
  };

  render() {
    return (
      <div className="login-center">
        <Row>
          <Col md={12} lg={12} className="header">
            <img src={pageLogo} className="" alt="" />
          </Col>
        </Row>
        <Row className="login-bg" style={{ backgroundImage: `url(${bgimg})` }}>
          <Col md={12} lg={12}>
            <div className="form-wrapper-right">
              <div className="form-wrapper-center">
                <AuthForm
                  authState={this.props.authState}
                  onChangeAuthState={this.handleAuthState}
                  onLogoClick={this.handleLogoClick}
                  history={this.props.history}
                  loginProcess={this.state.loginProcess}
                  ssoUser={helper.getQSParam('user')}
                  fromLogout={helper.getQSParam('fromLogout')}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default LoginCenterPage;
