import portalService from '../../services/portalService';
import { portalSlice } from './portalSlice';

export const sliceActions = portalSlice.actions;

export const fetchMenu = () => {
  return async dispatch => {
    const menus = await portalService.getMenu();
    dispatch(sliceActions.setMenu(menus));
  };
};

export const setSideMenu = flag => {
  return async dispatch => {
    dispatch(sliceActions.setSideMenu({ flag: flag }));
  };
};

export const setLeaveRequestPanel = flag => {
  return async dispatch => {
    dispatch(sliceActions.setLeaveRequestPanel({ flag: flag }));
  };
};

export const setRecentLeavePanel = flag => {
  return async dispatch => {
    dispatch(sliceActions.setRecentLeavePanel({ flag: flag }));
  };
};

export const setDOBPanel = flag => {
  return async dispatch => {
    dispatch(sliceActions.setDOBPanel({ flag: flag }));
  };
};

export const resetPopover = () => {
  return async dispatch => {
    dispatch(sliceActions.reset());
  };
};
