import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allStaffs: [],
  seatMap: [],
  wfoRecords: [],
  dobLoaded: false,
  dobList: [],
};

export const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    setStaff: (state, action) => {
      state.allStaffs = action.payload;
    },
    setSeatMap: (state, action) => {
      state.seatMap = action.payload;
    },
    updateSeatByStaff: (state, action) => {
      const { office, col, row, day, staffId, staffName } = action.payload;

      state.seatMap = state.seatMap.map(record => {
        if (record.Office === office && record.Col === col && parseInt(record.Row) === row) {
          return {
            ...record,
            [day]: staffId,
            [day + 'Name']: staffName,
          };
        }
        return record;
      });

      ////state.seatMap[office][seatIdx][day] = staffId;
      //state.seatMap[office][seatIdx][day + 'Name'] = staffName;
    },
    setWFO: (state, action) => {
      state.wfoRecords = action.payload;
    },
    setDOB: (state, action) => {
      state.dobList = action.payload;
      state.dobLoaded = true;
    },
  },
});
