import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSideMenuOpen: false,
  menus: [],
  isMenuLoaded: false,
  isDOBOpen: false,
  isLeaveRequestOpen: false,
  isRecentLeaveOpen: false,
};

export const portalSlice = createSlice({
  name: 'portal',
  initialState,
  reducers: {
    setSideMenu: (state, action) => {
      state.isSideMenuOpen = action.payload.flag;
    },

    setMenu: (state, action) => {
      state.menus = action.payload;
      state.isMenuLoaded = true;
    },

    setLeaveRequestPanel: (state, action) => {
      state.isLeaveRequestOpen = action.payload.flag;
    },

    setRecentLeavePanel: (state, action) => {
      state.isRecentLeaveOpen = action.payload.flag;
    },

    setDOBPanel: (state, action) => {
      state.isDOBOpen = action.payload.flag;
    },

    reset: state => {
      state.isDOBOpen = false;
      state.isLeaveRequestOpen = false;
      state.isRecentLeaveOpen = false;
    },
  },
});
