import { lazy } from 'react';

// Absences

// General
const ABSMyAbsences = lazy(() => import('./general/absMyAbsences'));
const ABSMyClaimedLeaves = lazy(() => import('./general/absMyClaimedLeaves'));
const ABSMyBalance = lazy(() => import('./general/absMyBalance'));
const ABSTodayList = lazy(() => import('./general/absTodayList'));
const ABSProjectView = lazy(() => import('./general/absProjectView'));
const ABSSummaryList = lazy(() => import('./general/absSummaryList'));

// Admin
const ABSAbsences = lazy(() => import('./admin/absAbsences'));
const ABSClaimLeave = lazy(() => import('./admin/absClaimLeave'));
const ABSManageProject = lazy(() => import('./admin/absManageProject'));
const ABSManageProjectTeam = lazy(() => import('./admin/absManageProjectTeam'));
const ABSBalance = lazy(() => import('./admin/absBalance'));
const ABSPublicHolidays = lazy(() => import('./admin/absPublicHolidays'));

// Year end
const ABSOpeninbMaint = lazy(() => import('./yearEnd/absOpeningMaint'));
const ABSConvertCF = lazy(() => import('./yearEnd/absConvertCF'));
const ABSPTOOpening = lazy(() => import('./yearEnd/ptoOpening'));
const ABSCFReport = lazy(() => import('./yearEnd/absCFReport'));
const ABSForfeitReport = lazy(() => import('./yearEnd/absForfeitedReport'));

export const routes = [
  // General
  {
    path: '/absMyAbsences',
    component: ABSMyAbsences,
  },
  {
    path: '/absMyClaimedLeaves',
    component: ABSMyClaimedLeaves,
  },
  {
    path: '/absMyBalance',
    component: ABSMyBalance,
  },
  {
    path: '/absTodayList',
    component: ABSTodayList,
  },
  {
    path: '/absProjectView',
    component: ABSProjectView,
  },
  {
    path: '/absSummaryList',
    component: ABSSummaryList,
  },

  // Admin
  {
    path: '/absAbsences',
    component: ABSAbsences,
  },
  {
    path: '/absClaimLeave',
    component: ABSClaimLeave,
  },
  {
    path: '/absManageProject',
    component: ABSManageProject,
  },
  {
    path: '/absManageProjectTeam',
    component: ABSManageProjectTeam,
  },
  {
    path: '/absBalance',
    component: ABSBalance,
  },
  {
    path: '/absPublicHolidays',
    component: ABSPublicHolidays,
  },

  //Year end
  {
    path: '/absOpeningMaint',
    component: ABSOpeninbMaint,
  },
  {
    path: '/absConvertCF',
    component: ABSConvertCF,
  },
  {
    path: '/absCFReport',
    component: ABSCFReport,
  },
  {
    path: '/absForfeitedReport',
    component: ABSForfeitReport,
  },
  {
    path: '/ptoOpening',
    component: ABSPTOOpening,
  },
];
