import { configureStore } from '@reduxjs/toolkit';
import { portalSlice } from './portal/portalSlice';
import { staffSlice } from './staff/staffSlice';
import { absenceSlice } from './absence/absenceSlice';
import { recognizingSlice } from './recognizing/recognizingSlice';

export const store = configureStore({
  reducer: {
    portal: portalSlice.reducer,
    staff: staffSlice.reducer,
    absence: absenceSlice.reducer,
    recognizing: recognizingSlice.reducer,
  },
});

export default store;
