import axios from 'axios';
import { getAPIHost } from '../utils/helper';
import '../utils/axios-interceptor';

export default {
  async getRecents(type) {
    const records = await axios.get(`${getAPIHost()}/api/index.php/recognition/list?type=${type}`);
    return records.data.result || [];
  },
  async getLikes() {
    const records = await axios.get(`${getAPIHost()}/api/index.php/recognition/getLikes`);
    return records.data.result || [];
  },
  async save(staffId, message, isFeedback = 0) {
    let records = await axios.post(`${getAPIHost()}/api/index.php/recognition/create`, {
      staffId: staffId,
      message: message,
      isFeedback: isFeedback >= 1 ? 1 : 0, // 0 - Recognition, 1- public feedback, 2-private feedback
      isPrivate: isFeedback === 2 ? 1 : 0,
    });
    if (records?.data?.statusCode === 200 && records?.data?.status === 'OK') {
      return {
        success: records?.data?.statusCode === 200,
        msg: records?.data?.result.message,
        lastId: records?.data?.result.lastId,
      };
    }
    return [];
  },
  async delete(recId) {
    let records = await axios.post(`${getAPIHost()}/api/index.php/recognition/delete?recId=${recId}`);

    if (records?.data?.statusCode === 200 && records?.data?.status === 'OK') {
      return {
        success: records?.data?.statusCode === 200,
        msg: records?.data?.result,
      };
    }
    return [];
  },
  async thumbUp(recId, value) {
    let records = await axios.post(`${getAPIHost()}/api/index.php/recognition/thumbUp?recId=${recId}&value=${value}`);

    if (records?.data?.statusCode === 200 && records?.data?.status === 'OK') {
      return {
        success: records?.data?.statusCode === 200,
        msg: records?.data?.result,
      };
    }
    return [];
  },
};
