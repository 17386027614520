import { EmptyLayout, LayoutRoute, MainLayout } from './framework-layout';
import PageSpinner from './components/PageSpinner';

import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import 'devextreme-react/radio-group';
import 'devextreme-react/tag-box';
import 'devextreme-react/select-box';
// Login center
import { STATE_LOGIN, STATE_LOGOUT } from './application-center/login-center/authForm';
import './utils/axios-interceptor';
//const uxlog = require('./application-center/ux-tracking-center');

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

// Import routes
const routes_shared = require('./routes');
// Combine all routes
const routes = [...routes_shared.routes];

const LoginCenterPage = routes_shared.LoginCenter;

// Global variables
// module, to hold the page object
window.message = {};
window.redirect = {};
window.popoverClose = [];

//window.UXLog = uxlog;

//window.UXLog.sendUXLogScheduler(); // start log scheduler
//console.log('UXLog started');

if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
  window.location.href = window.location.href.toLowerCase().replace('http', 'https');
}

const version = '1.0.5';
if (!localStorage.getItem('Version') || localStorage.getItem('Version') !== version) {
  localStorage.removeItem('sessionMenu');
  localStorage.removeItem('sessionMenuOri');
  localStorage.setItem('Version', version);
}
class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <Switch>
          <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={props => <LoginCenterPage {...props} authState={STATE_LOGIN} />}
          />

          <LayoutRoute
            exact
            path="/logout"
            layout={EmptyLayout}
            component={props => <LoginCenterPage {...props} authState={STATE_LOGOUT} />}
          />

          <MainLayout breakpoint={this.props.breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route exact path="/" component={routes_shared.DashboardPage} history={this.props.history} />
              <Route exact path="/home" component={routes_shared.DashboardPage} />

              {routes.map((c, i) => (
                <Route exact key={i} path={c.path} component={c.component} />
              ))}
            </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width <= 575) {
    return { breakpoint: 'xxs' };
  }

  if (576 <= width && width < 767) {
    return { breakpoint: 'xs' };
  }

  if (768 <= width && width < 991) {
    return { breakpoint: 'sm' };
  }

  if (992 <= width && width < 1199) {
    return { breakpoint: 'md' };
  }

  if (1200 <= width && width < 1920) {
    return { breakpoint: 'lg' };
  }

  return { breakpoint: 'xl' };
};

export default componentQueries(query)(App);
