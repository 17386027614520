import { lazy } from 'react';
// Performance

// General
const OHRMReview = lazy(() => import('./ohrmReview'));
const OHRMMyReviewList = lazy(() => import('./ohrmMyReviewList'));
const OHRMReviewMaintain = lazy(() => import('./ohrmReveiwMaintain'));
const OHRMReportTo = lazy(() => import('./ohrmReportTo'));
const OHRMHistorical = lazy(() => import('./ohrmHistorical'));

export const routes = [
  // Review
  {
    path: '/ohrmReview',
    component: OHRMReview,
  },
  {
    path: '/ohrmReviewMaintain',
    component: OHRMReviewMaintain,
  },
  {
    path: '/ohrmReportTo',
    component: OHRMReportTo,
  },
  {
    path: '/ohrmMyReviewList',
    component: OHRMMyReviewList,
  },
  {
    path: '/ohrmHistorical',
    component: OHRMHistorical,
  },
];
