import $ from 'jquery';

$('body').on('keydown', function (event) {
  //console.log(event, event.key);

  if (event.key === 'F2') {
    if ($('.cr-sidebar .menu-toggle > div')) {
      event.preventDefault();
      $('.cr-sidebar .menu-toggle > div').trigger('click');
    }
  }

  /*if (event.key === 'F3') {
    event.preventDefault();
    if ($('.menu-search input')) $('.menu-search input').trigger('focus');
  }*/

  if (event.key === 'l' && event.altKey) {
    event.preventDefault();
    if (window.location.pathname !== '/login') window.location.href = '/logout';
  }

  if (event.key === 'h' && event.altKey) {
    event.preventDefault();
    if (window.location.pathname !== '/login') {
      if (document.getElementById('navItem-home')) document.getElementById('navItem-home').click();
      else window.location.href = '/home';
    }
  }

  if (event.key === 'Escape') {
    // Try for Dx Popup
    if ($('.dx-overlay-wrapper.dx-datagrid-edit-popup')) {
      let dxPopup = $('.dx-overlay-wrapper.dx-datagrid-edit-popup');
      let dxPopupCancel = $(dxPopup).find('.dx-closebutton');
      dxPopupCancel.trigger('click');
    }
  }

  if (event.key === 's' && event.ctrlKey) {
    // Try for Dx Popup to save data
    if ($('.dx-overlay-wrapper.dx-datagrid-edit-popup')) {
      event.preventDefault();
      let dxPopup = $('.dx-overlay-wrapper.dx-datagrid-edit-popup');
      let dxPopupSave = $(dxPopup).find("[aria-label='Save']");
      dxPopupSave.trigger('click');
    }
  }

  if (event.key === 'n' && event.altKey) {
    // Try for add new button
    if ($('.dx-icon-edit-button-addrow')) {
      event.preventDefault();
      let dxNewBtn = $('.dx-icon-edit-button-addrow');
      dxNewBtn.trigger('click');
    }
  }

  /*if (event.key === 'f' && event.ctrlKey) {
    // Try for grid filter
    if ($('.dx-row.dx-datagrid-filter-row')) {
      event.preventDefault();
      let dxFilterRow = $('.dx-row.dx-datagrid-filter-row');
      let firstCell = $(dxFilterRow).find(
        '.dx-first-cell.dx-editor-cell input',
      );
      firstCell.trigger('focus');
    }
  }*/

  if ((event.key === 'ArrowRight' || event.key === 'ArrowLeft') && event.ctrlKey) {
    // To navigate prev or next page for paging
    if ($('.dx-widget.dx-datagrid-pager.dx-pager')) {
      event.preventDefault();
      let dxPaging = $('.dx-widget.dx-datagrid-pager.dx-pager');
      if (event.key === 'ArrowLeft') {
        // prev page
        let dxPagingBtn = $(dxPaging).find('.dx-navigate-button.dx-prev-button').not('.dx-button-disable');
        if (dxPagingBtn) dxPagingBtn.trigger('click');
      }

      if (event.key === 'ArrowRight') {
        // next page
        let dxPagingBtn = $(dxPaging).find('.dx-navigate-button.dx-next-button').not('.dx-button-disable');
        if (dxPagingBtn) dxPagingBtn.trigger('click');
      }
    }
  }
});
