import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from 'reactstrap';
import { AiFillLike, AiOutlineLike } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFeedback, thumbAction } from '../../../store/recognizing/recognizingActions';

const Card = ({ data, canDelete = false }) => {
  const dispatch = useDispatch();
  const { allStaffs } = useSelector(state => state.staff);
  const { allLikes } = useSelector(state => state.recognizing);
  const [staffNames, setStaffNames] = useState({ fromName: '', toName: '' });
  const [likeNames, setLikeNames] = useState([]);

  useEffect(() => {
    const fromStaff = allStaffs.find(staff => staff.StaffId === data.CreatedBy);

    let toNames = [];
    if (data.ToUser) {
      const toUsers = data.ToUser.split(',');
      toUsers.map(t => {
        const staffName = allStaffs.find(staff => staff.StaffId === t);
        toNames.push(staffName?.StaffName);
      });
    }

    setStaffNames({
      fromName: fromStaff?.StaffName || '',
      toName: toNames.join(', ') || '',
    });
  }, [allStaffs, data]);

  useEffect(() => {
    const filteredLikes = allLikes.filter(like => like.RefRecId.toString() === data.RecId.toString());
    const filteredNames = filteredLikes.map(
      like => allStaffs.find(staff => staff.StaffId === like.ModifiedBy)?.StaffName,
    );
    setLikeNames(filteredNames);
  }, [allLikes, allStaffs, data]);

  const handleThumbUp = async e => {
    e.preventDefault();
    if (data.MySelf !== '1') {
      let newData = { ...data, Value: data.Value === '0' ? '1' : '0' };
      dispatch(thumbAction(newData));
    }
  };

  const handleDelete = async () => {
    if (confirm('Are you sure to delete this record?')) {
      dispatch(deleteFeedback(data.RecId));
    }
  };

  const Private = () => {
    return <div className="private">Private</div>;
  };

  return (
    <div className="recCard">
      {data.IsPrivate === '1' && <Private />}
      <div className="datetime">{moment(data.CreatedDate).format('dddd, MMMM Do YYYY, h:mm:ss a')}</div>
      <div className="cardmessage">
        {staffNames.fromName && (
          <>
            <div className="creater">{staffNames.fromName}</div>
            {data.IsFeedback === '1' ? "'s feedback: " : ' recognized '}
          </>
        )}
        {data.IsFeedback === '0' && (
          <>
            <div className="target">{staffNames.toName}</div> for{' '}
          </>
        )}
        {data.IsFeedback === '1' && staffNames.toName !== '' && (
          <>
            (attn: <div className="target">{staffNames.toName}</div>){' '}
          </>
        )}
        <div className="details">{data.Comments}</div>
      </div>
      <div className="cardaction">
        <span title={likeNames.join('\n')}>
          {parseInt(data.Value) === 1 && (
            <AiFillLike size={18} className="selected" onClick={handleThumbUp} onDoubleClick={null} />
          )}
          {parseInt(data.Value) === 0 && <AiOutlineLike size={18} onClick={handleThumbUp} onDoubleClick={null} />}
        </span>
        <div className="count">{parseInt(data.TotalLikes) + parseInt(data.Value)}</div>
        {canDelete && (
          <Button className="delete" onClick={handleDelete}>
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

export default Card;
