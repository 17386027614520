import axios from 'axios';

const helper = require('../../../utils/helper');
const ux = require('../../ux-tracking-center');
//const enums = require('../../../enums');

export const asLocalAdmin = () => {
  return (
    helper.getEnvironment() === 'LOCAL' || helper.getEnvironment() === 'ISS'
  ); // this is for local server use only
};

export const getAccessToken = () => localStorage.getItem('appToken') || '';

export const isAuthenticated = () => {
  const accessToken = getAccessToken();
  const userID = helper.getUserId();
  if (!accessToken || !userID) {
    resetUserData();
    return false;
  }
  return true;
};

export const doLogin = async seed => {
  const { username, password } = seed;

  resetUserData();

  try {
    let logindata = {};
    let url = '/api/index.php/login/login';
    let headers = {
      'Content-Type': 'application/json',
    };

    logindata = { username: username, password: password };

    return await axios({
      url: helper.getAPIHost() + url,
      method: 'POST',
      data: logindata,
      headers: headers,
    })
      .then(async response => {
        console.log('login', response);

        const result = response.data.result;
        if (response.data.statusCode === 200 && response.data.status === 'OK') {
          const user = result;

          // basic profile
          localStorage.setItem('UserId', user.StaffId);
          localStorage.setItem('UserName', user.StaffName);

          const initials = user?.StaffName?.match(/(\b\S)?/g)
            .join('')
            .match(/(^\S|\S$)?/g)
            .join('')
            .toUpperCase();
          localStorage.setItem('UserInitial', initials);
          localStorage.setItem('User', JSON.stringify(user));
          localStorage.setItem('role', user.Role);

          localStorage.setItem('appToken', user.appToken);

          // Menu
          localStorage.setItem('MenuSideBarOpen', false);

          helper.catchServer(response.data);

          return {
            status: true,
            message: '',
            isAdmin: false,
            isFloater: false,
            requiredChangePwd: user.requiredChangePwd
          };
        } else {
          ux.logError(
            'Framework',
            'Login',
            response.data.result,
            JSON.stringify(logindata),
          );
          return {
            status: false,
            message: response.data.result,
          };
        }
      })
      .catch(error => {
        ux.logError(
          'Framework',
          'Login',
          error.message,
          JSON.stringify(logindata),
        );
        console.log(error);
        return {
          status: false,
          message: error.message,
        };
      });
  } catch (error) {
    ux.logError('Framework', 'Login', 'Error on login process!', error);
    Promise.resolve({
      status: false,
      message: 'Error on login process!',
    });
  }
};

export const doLogout = async (redirect = true) => {
  console.log('In Logout');
  /*if (localStorage.getItem('appToken')) {
    await axios({
      url: helper.getAPIHost() + '/api/Logout/',
      method: 'POST',
      data: {},
      headers: helper.apiHeaders(),
    }).then(async response => {
      try {
        console.log('Logout from server', response);
      } catch (e) {
        console.log(e);
      }
    });
  }*/
  ux.logExit();
  resetUserData();

  // for admin login again after SSo
  // localStorage.removeItem('isAdminLoginSSo');
  //history.push("/login")
  if (redirect) window.location.href = '/login';

  return true;
};

export const resetUserData = () => {
  // Token information
  localStorage.removeItem('UserId');
  localStorage.removeItem('UserName');
  localStorage.removeItem('UserInitial');
  localStorage.removeItem('User');
  localStorage.removeItem('role');

  localStorage.removeItem('appToken');

  localStorage.removeItem('sessionMenu');
  localStorage.removeItem('sessionMenuOri');
  localStorage.removeItem('MenuPinned');
  localStorage.removeItem('MenuSideBarOpen');

  console.log('Data cleared');
};
