import LoginCenterPage from './application-center/login-center';
import TestHome from './modules/home';

export const LoginCenter = LoginCenterPage;
export const DashboardPage = TestHome;

const routes_absences = require('./modules/absences/routes');
const routes_profile = require('./modules/profiles/routes');
const routes_timecards = require('./modules/timecards/routes');
// const roates_performance = require('./modules/performance/routes');
const roates_ohrm = require('./modules/ohrm/routes');

export const routes = [
  ...routes_absences.routes,
  ...routes_profile.routes,
  ...routes_timecards.routes,
  // ...roates_performance.routes,
  ...roates_ohrm.routes,
];
