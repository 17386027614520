import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, PopoverBody, Badge } from 'reactstrap';
import { GoTasklist } from 'react-icons/go';
import './style.scss';
import { fetchRequestList } from '../../../../store/absence/absenceActions';
import { setDOBPanel, setLeaveRequestPanel, setRecentLeavePanel } from '../../../../store/portal/portalActions';

const AbsenceApprovalCard = ({ history }) => {
  const dispatch = useDispatch();
  const { requestList, requestListLoaded } = useSelector(s => s.absence);
  const { isLeaveRequestOpen } = useSelector(s => s.portal);
  const [isPopover, setPopover] = useState(false);

  useEffect(() => {
    if (!requestListLoaded) {
      dispatch(fetchRequestList());
    }
  }, []);

  useEffect(() => {
    setPopover(isLeaveRequestOpen);
  }, [isLeaveRequestOpen]);

  const togglePopover = useCallback(
    e => {
      e.preventDefault();
      dispatch(setLeaveRequestPanel(!isPopover));
      if (!isPopover) {
        dispatch(setDOBPanel(false));
        dispatch(setRecentLeavePanel(false));
      }
    },
    [isPopover],
  );

  const closePopover = useCallback(() => {
    dispatch(setLeaveRequestPanel(false));
  }, []);

  const goToAbsence = useCallback(staffId => {
    if (staffId) history.push(`/absAbsences?staffId=${staffId}&rand=${Math.random()}`);
    else history.push('/absAbsences');
    closePopover();
  }, []);

  return (
    <div className="approval-card">
      <div>
        <GoTasklist size={30} id="Popover-Approval" onClick={togglePopover} title="Approvals" />
        <Badge className="badge" color="success" onClick={togglePopover}>
          {requestList.length}
        </Badge>
      </div>

      {isPopover && (
        <Popover
          placement="bottom-end"
          isOpen={isPopover}
          target="Popover-Approval"
          className="p-0 border-0 popover-approval"
          style={{ minWidth: 250 }}
        >
          <PopoverBody className="p-0 border-light">
            <span className="title">Leave request list</span>
            <div className="list">
              {requestList.length > 0 && (
                <span onClick={() => goToAbsence('')} className="viewAll">
                  View all
                </span>
              )}
              {requestList.map((b, i) => (
                <span key={i} onClick={() => goToAbsence(b.StaffId)}>
                  {b.StaffName}
                </span>
              ))}
              {requestList.length === 0 && 'No record found.'}
            </div>
          </PopoverBody>
        </Popover>
      )}
    </div>
  );
};

export default AbsenceApprovalCard;
