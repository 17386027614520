import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import officeService from '../../../services/officeService';
import { MdOutlineFiberNew } from 'react-icons/md';
import styles from './style.module.scss';

export const Announcement = ({ history, newScheduleIndicator, showSeatCTA }) => {
  const [guideURL, setGuide] = useState('');
  const [documentations, setDocs] = useState([]);
  const [dob, setDOB] = useState('1900-01-01');

  useEffect(() => {
    officeService.getGuideURL().then(url => setGuide(url));
    officeService.getDocumentationsURL().then(docs => setDocs(docs));
    const user = JSON.parse(localStorage.getItem('User')) || {};
    setDOB(user.Birthdate || '1900-01-01');
  }, []);

  const handleLinkClick = path => {
    history.push(path);
  };

  const handleGotoSeatClick = () => {
    showSeatCTA(true);
  };

  return (
    <div className={styles['announcement-section']}>
      <div>
        <Link to="/absMyAbsences">Absences</Link> | <Link to="/otClaim">OT</Link> |{' '}
        <Link to="/oncallClaim">Oncall</Link> | <Link to="/absMyClaimedLeaves">RL/CashOut</Link>
      </div>

      <div>
        <a
          href="https://isoftstonenae.sharepoint.com/:w:/s/MyIsoftstone/EXYByMlBQ4RLieNArN2khh8BKaAAp3MuqPKkb86VWFTh1Q?e=2CdqB6"
          target="_blank"
          rel="noreferrer"
        >
          MyESS Guide
        </a>
        {guideURL && (
          <>
            {' '}
            |{' '}
            <a href={guideURL} target="_blank" rel="noreferrer" className="guideText">
              {localStorage.getItem('role') === 'lead' ? 'Team Lead' : 'Admin'} Guide
            </a>
          </>
        )}
      </div>
      {documentations &&
        documentations.map((doc, i) => {
          return (
            <div key={i}>
              <a href={doc.URL} target="_blank" rel="noreferrer" className="guideText">
                {doc.Title}
              </a>
            </div>
          );
        })}

      {dob === '1900-01-01' && (
        <div>
          Please update your birthdate at{' '}
          <a className="link" onClick={() => handleLinkClick('/profile')}>
            Profile
          </a>
        </div>
      )}

      <div className="link" onClick={handleGotoSeatClick}>
        Seat Information
        {newScheduleIndicator && <MdOutlineFiberNew className={styles['schedule-updated']} size={30} />}
      </div>
    </div>
  );
};
