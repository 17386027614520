import React, { useState, useEffect, useCallback } from 'react';
import { Popover, PopoverBody, Badge } from 'reactstrap';
import { MdCake } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDOBList } from '../../../../store/staff/staffActions';
import './style.scss';
import { setDOBPanel, setLeaveRequestPanel, setRecentLeavePanel } from '../../../../store/portal/portalActions';

const BirthdayCard = () => {
  const dispatch = useDispatch();
  const { dobList, dobLoaded } = useSelector(s => s.staff);
  const { isDOBOpen } = useSelector(s => s.portal);

  const [today, setToday] = useState(new Date().getDate());
  const [todayList, setTodayList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [isPopover, setPopover] = useState(false);

  useEffect(() => {
    let dateChanged = false;
    const intervalId = setInterval(() => {
      const now = new Date();
      if (now.getDate() !== today) {
        dateChanged = true;
        console.log('Date Change');
      }
      setToday(now.getDate());
    }, 21 * 1e5);

    if (!dobLoaded || dateChanged) {
      dispatch(fetchDOBList());
    }

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setPopover(isDOBOpen);
  }, [isDOBOpen]);

  useEffect(() => {
    const today = new Date().getDate();
    setMonthList(dobList);
    setTodayList(dobList.filter(d => d.Day === today.toString()));
  }, [dobList]);

  const togglePopover = useCallback(
    e => {
      e.preventDefault();
      dispatch(setDOBPanel(!isPopover));
      if (!isPopover) {
        dispatch(setLeaveRequestPanel(false));
        dispatch(setRecentLeavePanel(false));
      }
    },
    [isPopover],
  );

  return (
    <div className="birthday-card">
      <div>
        <MdCake size={30} id="Popover-Birthday" onClick={togglePopover} title="Today birthday" />
        <Badge className="badge" color="success" onClick={togglePopover}>
          {todayList.length}
        </Badge>
      </div>

      {isPopover && (
        <Popover
          placement="bottom-end"
          isOpen={isPopover}
          target="Popover-Birthday"
          className="p-0 border-0 popover-birthday"
          style={{ minWidth: 250 }}
        >
          <PopoverBody className="p-0 border-light">
            <span className="title">Today birthday list</span>
            <div className="list">
              {todayList.map((b, i) => (
                <div key={i}>{b.StaffName}</div>
              ))}
              {todayList.length === 0 && 'No record found.'}
            </div>

            <div className="list">
              <span className="title">This Month</span>
              {monthList.map((b, i) => (
                <div key={i}>
                  {b.StaffName} - {b.Day}
                </div>
              ))}
              {monthList.length === 0 && 'No record found.'}
            </div>
          </PopoverBody>
        </Popover>
      )}
    </div>
  );
};

export default BirthdayCard;
