import axios from 'axios';
import { getAPIHost } from '../utils/helper';
import '../utils/axios-interceptor';

export default {
  async getRequestList(year = new Date().getFullYear()) {
    const records = await axios.get(`${getAPIHost()}/api/index.php/absence/listRequests?year=${year}&all=1`);
    return records.data.result.sort((a, b) => a.StaffName.localeCompare(b.StaffName)) || [];
  },
  async getTodayList() {
    const records = await axios.get(`${getAPIHost()}/api/index.php/absence/listToday`);
    return records.data.result.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime()) || [];
  },
  async getSummaryList(year) {
    const records = await axios.get(`${getAPIHost()}/api/index.php/absence/listSummary?year=${year}`);
    return records.data.result || [];
  },
};
