import PropTypes from 'prop-types';
import React from 'react';

//import { RiShieldUserLine } from 'react-icons/ri';
import PageSpinner from '../../components/PageSpinner';

//import classnames from 'classnames';
//import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import NormalLoginForm from './normalLogin';

const initData = require('./lib/dataInitialization');
const auth = require('./lib/authentication');

class AuthForm extends React.Component {
  state = {
    loginFormType: 'default', // default, 2faemail, 2fasms
  };

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  ssoLoginCall(userId, fromSSOCheck) {
    // to do: need?
    console.log(fromSSOCheck);
    if (userId) {
      if (userId.indexOf('\\') >= 0) {
        let tmp = userId.split('\\');
        userId = tmp[1];
      }
      console.log('SSO-User: ' + userId);
      auth.resetUserData();
      this.setState({
        loginProcess: true,
        errorMessage: '',
        ssoLoginProcess: true,
      });
      auth
        .doLogin(userId, '', true) // true for SSO
        .then(result => {
          if (result.status) {
            console.log('SSO-Login success');

            Promise.all([
              //initData.initFiscalMonth(),
              //initData.initDashboardKPI(),
              initData.initParameters(),
              // if more data need to init, append here
            ]).then(() => {
              //this.props.history.push("/home");
              window.location.href = '/home';
            });
          } else {
            console.log('SSO-Login failed');
            this.setState({
              errorMessage: result.message,
              loginProcess: false,
              ssoLoginProcess: true,
            });
          }
        });
    }
  }

  toggleLoginMethod = tabId => {
    this.setState({
      loginFormType: tabId,
    });
  };

  render() {
    if (this.props.authState === 'LOGOUT') auth.doLogout();
    else if (auth.isAuthenticated()) window.location.href = '/home';
    else if (!localStorage.getItem('2fa')) {
      auth.resetUserData();
    }

    return (
      <>
        {this.state.loginProcess && <PageSpinner />}
        <div className="title">{process.env.REACT_APP_NAME}</div>
        {this.state.errorMessage && (
          <div className="login-error">
            <span>Error: </span>
            {this.state.errorMessage}
          </div>
        )}
        <NormalLoginForm history={this.props.history} />
      </>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_LOGOUT = 'LOGOUT';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_LOGOUT, STATE_SIGNUP]).isRequired,
  usernameInputProps: PropTypes.object,
  passwordInputProps: PropTypes.object,
  ssoUser: PropTypes.string,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',

  usernameInputProps: {
    id: 'username',
    type: 'text',
    placeholder: 'Username',
    required: 'required',
  },
  passwordInputProps: {
    id: 'password',
    type: 'password',
    placeholder: 'Password',
    required: 'required',
  },
  ssoUser: '',
  fromLogout: '',
};

export default AuthForm;
