import axios from 'axios';
import { getAPIHost } from '../utils/helper';
import '../utils/axios-interceptor';

export default {
  async getLastList() {
    const records = await axios.get(`${getAPIHost()}/api/index.php/timecard/last`);
    return records.data.result || {};
  },
  async getStaffRTKCount(date) {
    try {
      let records = await axios.get(`${getAPIHost()}/api/index.php/rtk/staffRTKCount?date=${date}`);

      if (records?.data?.statusCode === 200 && records?.data?.status === 'OK') {
        return records?.data?.result;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  },
  async getLikes() {
    const records = await axios.get(`${getAPIHost()}/api/index.php/recognition/getLikes`);
    return records.data.result || [];
  },
  async createRecord(payload) {
    let records = await axios.post(`${getAPIHost()}/api/index.php/timecard/newTimeCard`, payload);
    if (records?.data?.statusCode === 200 && records?.data?.status === 'OK') {
      return {
        success: records?.data?.statusCode === 200,
        msg: records?.data?.result,
      };
    }
    return [];
    //return { success: false, msg: error.message };
  },
  async delete(recId) {
    let records = await axios.post(`${getAPIHost()}/api/index.php/recognition/delete?recId=${recId}`);

    if (records?.data?.statusCode === 200 && records?.data?.status === 'OK') {
      return {
        success: records?.data?.statusCode === 200,
        msg: records?.data?.result,
      };
    }
    return [];
  },
  async thumbUp(recId, value) {
    let records = await axios.post(`${getAPIHost()}/api/index.php/recognition/thumbUp?recId=${recId}&value=${value}`);

    if (records?.data?.statusCode === 200 && records?.data?.status === 'OK') {
      return {
        success: records?.data?.statusCode === 200,
        msg: records?.data?.result,
      };
    }
    return [];
  },
};
