// Construct menu list structure

export const constructMenuStructure = menus => {
  let menuList = JSON.parse(JSON.stringify(menus));

  // Sort by sequence
  menuList = menuList.sort((a, b) => {
    return parseInt(a.Sequence) - parseInt(b.Sequence);
  });
  //console.log("Menu:Sorted", JSON.parse(JSON.stringify(menuList)))

  // Get master level menu list
  let masterList = menuList.filter(m => parseInt(m.ParentId) === 0 && parseInt(m.Sequence) > 0);
  //console.log("Menu:MasterList", JSON.parse(JSON.stringify(masterList)))

  // Based on master list, get each's L-1 list
  masterList.map(mMaster => {
    mMaster.level = 1;

    let level_1_list = menuList.filter(m1 => parseInt(m1.ParentId) === parseInt(mMaster.RecId));

    //console.log("Menu:Level-1", mMaster.MenuLabel, JSON.parse(JSON.stringify(level_1_list)))

    // Get L-2 list
    level_1_list.map(mL1 => {
      mL1.level = 2;
      let level_2_list = menuList.filter(m2 => parseInt(m2.ParentId) === parseInt(mL1.RecId));
      level_2_list.map(m => {
        m.level = 2;
      });
      //console.log("Menu:Level-2", mL1.MenuLabel, JSON.parse(JSON.stringify(level_2_list)))
      mL1.children = level_2_list;
    });

    mMaster.children = level_1_list;
  });

  //console.log("Menu:Structured", masterList)
  return masterList;
};
