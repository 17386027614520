import axios from 'axios';
const helper = require('../../../utils/helper');

export const initParameters = async () => {
  try {
    return await axios({
      url: helper.getAPIHost() + '/api/ParameterSetup',
      method: 'get',
      headers: helper.apiHeaders(),
    })
      .then(async response => {
        let params = [];
        if (response.data.result) {
          params = response.data.result;

          let paramKeys = {};
          params.map(p => {
            paramKeys[p.paramName] = p.paramValue;
            return 0;
          });
          localStorage.setItem('Parameters', JSON.stringify(paramKeys));
        } else {
          localStorage.setItem('Parameters', JSON.stringify({}));
        }
      })
      .catch(e => {
        console.log('In initParameters Error catch', e);
        localStorage.setItem('Parameters', JSON.stringify({}));
        return true;
      });
  } catch (err) {
    console.log('In initParameters Error catch2 ' + err);
    console.log(err);
    localStorage.setItem('Parameters', JSON.stringify({}));
    return true;
  }
};
