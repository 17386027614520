import axios from 'axios';
import { getAPIHost } from '../utils/helper';
import '../utils/axios-interceptor';

export default {
  async getAllStaffs(scope = 'basic', status = '1') {
    const staffs = await axios.get(`${getAPIHost()}/api/index.php/staff/list?scope=${scope}&status=${status}`);
    return staffs.data.result || [];
  },
  async createRecord(payload) {
    try {
      let validate = await axios.post(`${getAPIHost()}/api/index.php/staff/create`, payload);
      return {
        success: validate?.data?.statusCode === 200,
        msg: validate?.data?.result,
      };
    } catch (error) {
      return { success: false, msg: error.message };
    }
  },
  async updateRecord(payload) {
    try {
      let validate = await axios.post(`${getAPIHost()}/api/index.php/staff/update?recId=${payload.RecId}`, payload);
      return {
        success: validate?.data?.statusCode === 200,
        msg: validate?.data?.result,
      };
    } catch (error) {
      return { success: false, msg: error.message };
    }
  },

  async getDistanceList() {
    try {
      let records = await axios.get(`${getAPIHost()}/api/index.php/staff/listDistanceAvg`);

      if (records?.data?.statusCode === 200 && records?.data?.status === 'OK') {
        let ret = records?.data?.result?.avg;
        ret = ret.concat(records?.data?.result?.list);
        return ret;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  },
  async getDistanceByDateList(date) {
    try {
      let records = await axios.get(`${getAPIHost()}/api/index.php/staff/listDistanceByDate?date=${date}`);

      if (records?.data?.statusCode === 200 && records?.data?.status === 'OK') {
        let ret = records?.data?.result;
        return ret;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  },
  async getSeatMap() {
    const staffs = await axios.get(getAPIHost() + '/api/index.php/office/getSeatMap');
    return staffs.data.result || [];
  },
  async updateSeatCard(office, col, row, day, staffId) {
    let records = await axios.post(`${getAPIHost()}/api/index.php/office/updateSeat`, {
      office: office,
      col: col,
      row: row,
      day: day,
      staffId: staffId,
    });

    if (records?.data?.statusCode === 200 && records?.data?.status === 'OK') {
      return {
        success: records?.data?.statusCode === 200,
        msg: records?.data?.result,
      };
    }
    return [];
  },
  async getWFOList() {
    const records = await axios.get(`${getAPIHost()}/api/index.php/staff/listScheduleList`);
    return records.data.result || [];
  },
  async getStaffRTKCount(date) {
    try {
      let records = await axios.get(`${getAPIHost()}/api/index.php/rtk/staffRTKCount?date=${date}`);
      if (records?.data?.statusCode === 200 && records?.data?.status === 'OK') {
        return records?.data?.result;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  },
  async getBirthdayListByMonth() {
    try {
      let records = await axios.get(`${getAPIHost()}/api/index.php/staff/listBirthdayByMonth`);
      if (records?.data?.statusCode === 200 && records?.data?.status === 'OK') {
        return records?.data?.result;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  },
};
