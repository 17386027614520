import { lazy } from 'react';

// General
const TCMissingLog = lazy(() => import('./general/tcMissingLog'));
const TCAttandanceLog = lazy(() => import('./general/timecards'));
const TCAttandanceLogByStaff = lazy(() => import('./general/tcAttLogStaff'));

const OTClaim = lazy(() => import('./general/otClaim'));
const OTClaimStatus = lazy(() => import('./general/otClaimStatus'));
const OCClaim = lazy(() => import('./general/oncallClaim'));

// Admin
const OTReview = lazy(() => import('./admin/otReview'));
const OTReplacement = lazy(() => import('./admin/otReplacement'));
const OTDirectCashOut = lazy(() => import('./admin/otDirectCashOut'));
const OTApproval = lazy(() => import('./admin/otApproval'));
const OTProcess = lazy(() => import('./admin/otProcess'));
const OTAllProcessed = lazy(() => import('./admin/otAllProcessed'));
const OTAllStatus = lazy(() => import('./admin/otAllStatus'));

const OCReview = lazy(() => import('./admin/ocReview'));
const OCApproval = lazy(() => import('./admin/ocApproval'));
const OCProcess = lazy(() => import('./admin/ocProcess'));
const OCAllProcessed = lazy(() => import('./admin/ocAllProcessed'));
const OCAllStatus = lazy(() => import('./admin/ocAllStatus'));

export const routes = [
  // General
  {
    path: '/tcAttLog',
    component: TCAttandanceLog,
  },
  {
    path: '/tcMissingLog',
    component: TCMissingLog,
  },
  {
    path: '/tcAttLogStaff',
    component: TCAttandanceLogByStaff,
  },
  {
    path: '/otClaim',
    component: OTClaim,
  },
  {
    path: '/otClaimStatus',
    component: OTClaimStatus,
  },
  {
    path: '/oncallClaim',
    component: OCClaim,
  },

  // Admin
  {
    path: '/otReview',
    component: OTReview,
  },
  {
    path: '/otReplacement',
    component: OTReplacement,
  },
  {
    path: '/otDirectCashOut',
    component: OTDirectCashOut,
  },
  {
    path: '/otApproval',
    component: OTApproval,
  },
  {
    path: '/otProcess',
    component: OTProcess,
  },
  {
    path: '/otAllProcessed',
    component: OTAllProcessed,
  },
  {
    path: '/otAllStatus',
    component: OTAllStatus,
  },
  {
    path: '/ocReview',
    component: OCReview,
  },
  {
    path: '/ocApproval',
    component: OCApproval,
  },
  {
    path: '/ocProcess',
    component: OCProcess,
  },
  {
    path: '/ocAllProcessed',
    component: OCAllProcessed,
  },
  {
    path: '/ocAllStatus',
    component: OCAllStatus,
  },

  /*
  {
    path: '/namelist',
    component: ProfileNameList,
  },*/
];
