module.exports = global.enums = {
  notificationType: {
    E: 'error',
    I: 'info',
    W: 'warning',
    S: 'success',
  },
  tgGPS: {
    lat: 3.1080398,
    lng: 101.4595353,
  },
  IWKGPS: {
    lat: 3.1532659,
    lng: 101.6656826,
  },

  pagingRecordPerPageOptions: [10, 20, 50, 100],
  pagingDefaultPageSize: 20,

  leaveTypeMaster: [
    { item: 'PTO', value: 'PTO' },
    { item: 'Time-off', value: 'TO' },
    { item: 'EL', value: 'EL' },
    //{ item: 'Request Work From Home', value: 'WFH' },
    { item: 'Un-paid Leave', value: 'UP' },
    { item: 'Medical Leave', value: 'M' },
    { item: 'Replacement Leave', value: 'R' },
    { item: 'Carry Forward', value: 'CF' },
    { item: 'Marriage Leave', value: 'MR' },
    { item: 'Maternity Leave', value: 'ML' },
    { item: 'Paternity Leave', value: 'PL' },
    { item: 'Hospitalization', value: 'H' },
    //{ item: 'Quarantine', value: 'Q' },
    { item: 'Compasionate Leave', value: 'COM' },
    //{ item: 'Others', value: 'OTH' },
    { item: 'PTO - To Next Year CF', value: 'TCF' },
    { item: 'PTO - Forfeited', value: 'F' },
  ],

  leaveTypeInput: [
    { item: 'PTO', value: 'PTO' },
    { item: 'Time-off', value: 'TO' },
    //    { item: 'Request Work From Home', value: 'WFH' },
    { item: 'Un-paid Leave', value: 'UP' },
    { item: 'Medical Leave', value: 'M' },
    { item: 'Replacement Leave', value: 'R' },
    { item: 'Carry Forward', value: 'CF' },
    { item: 'Marriage Leave', value: 'MR' },
    { item: 'Maternity Leave', value: 'ML' },
    { item: 'Paternity Leave', value: 'PL' },
    { item: 'Hospitalization', value: 'H' },
    { item: 'Compasionate Leave', value: 'COM' },
    //{ item: 'Others', value: 'OTH' },
  ],

  blockLeaveTypeInput: [
    { item: 'PTO', value: 'PTO' },
    { item: 'Un-paid Leave', value: 'UP' },
    //{ item: 'Medical Leave', value: 'M' },
    { item: 'Maternity Leave', value: 'ML' },
    { item: 'Hospitalization', value: 'H' },
  ],

  leaveDayTypeMaster: [
    { item: '1 hour', value: '1' },
    { item: '2 hours', value: '2' },
    { item: '3 hours', value: '3' },
    { item: '4 hours', value: '4' },
    { item: '5 hours', value: '5' },
    { item: '6 hours', value: '6' },
    { item: '7 hours', value: '7' },
    { item: '8 hours', value: '8' },
    { item: 'AM', value: 'AM' },
    { item: 'PM', value: 'PM' },
    { item: 'Full day (8 hours)', value: 'FD' },
  ],

  leaveDayTypeInput: [
    { item: 'AM', value: 'AM' },
    { item: 'PM', value: 'PM' },
    { item: 'Full day (8 hours)', value: 'FD' },
  ],

  leaveStatusMaster: [
    { item: 'New', value: 'New' },
    { item: 'Approved', value: 'Approved' },
    { item: 'Cancelled', value: 'Cancelled' },
    { item: 'Rejected', value: 'Rejected' },
    { item: 'Deleted', value: 'Deleted' },
  ],

  noYesList: [
    { item: 'Yes', value: '1' },
    { item: 'No', value: '0' },
  ],

  staffStatusList: [
    { item: 'Active', value: '1' },
    { item: 'Terminated', value: '0' },
    { item: 'Deleted', value: '2' },
  ],

  staffRoleList: [
    { item: 'Staff', value: 'staff', level: 0 },
    { item: 'Admin', value: 'admin', level: 99 },
    { item: 'Manager', value: 'manager', level: 80 },
    { item: 'Lead', value: 'lead', level: 50 },
  ],

  wfoList: [
    { item: 'N/A', value: '' },
    { item: 'Night Shift', value: 'NS' },
    { item: '1 day/week', value: '1D1W' },
    { item: '2 days/week', value: '2D1W' },
    { item: '3 days/week', value: '3D1W' },
    { item: '4 days/week', value: '4D1W' },
    { item: '3 days/2 week', value: '3D2W' },
    { item: '5 days/2 weeks', value: '5D2W' },
    { item: 'Every Day WFO', value: '5D' },
    { item: 'Flexi', value: 'F' },
  ],
  /*departmentList: [
    { item: 'COMPANY', value: 'COMPANY' },
    { item: 'DEV', value: 'IT' },
    { item: 'SupportQA', value: 'SQA' },
    { item: 'HR', value: 'HR' },
    { item: 'AVON', value: 'AVON' },
    { item: 'XiaoMi', value: 'XM' },
    { item: 'BA', value: 'BA' },
  ],

  officeList: [
    { item: '11A', value: '11A' },
    { item: '11E', value: '11E' },
    { item: 'WFH', value: 'WFH' },
    { item: 'Client', value: 'CLIENT' },
  ],
*/

  timeCardOfficeList: [
    { item: 'Office-11A', value: '11A' },
    { item: 'Office-11E', value: '11E' },
    { item: 'WFH', value: 'Home' },
    { item: 'AVON', value: 'AVON' },
  ],

  claimLeaveTypeMaster: [
    { item: 'Replacement', value: 'R' },
    { item: 'Carry Forward', value: 'CF' },
  ],

  claimLeaveStatusMaster: [
    { item: 'New', value: 'N' },
    { item: 'Cancelled', value: 'C' },
    { item: 'Partial', value: 'P' },
    { item: 'Fully Utilized', value: 'U' },
    { item: 'Expired', value: 'E' },
  ],

  monthSelect: [
    { item: 'All', value: 0 },
    { item: 'Jan', value: 1 },
    { item: 'Feb', value: 2 },
    { item: 'Mar', value: 3 },
    { item: 'Apr', value: 4 },
    { item: 'May', value: 5 },
    { item: 'Jun', value: 6 },
    { item: 'Jul', value: 7 },
    { item: 'Aug', value: 8 },
    { item: 'Sep', value: 9 },
    { item: 'Oct', value: 10 },
    { item: 'Nov', value: 11 },
    { item: 'Dec', value: 12 },
  ],

  monthSelectInput: [
    { item: 'Jan', value: 1 },
    { item: 'Feb', value: 2 },
    { item: 'Mar', value: 3 },
    { item: 'Apr', value: 4 },
    { item: 'May', value: 5 },
    { item: 'Jun', value: 6 },
    { item: 'Jul', value: 7 },
    { item: 'Aug', value: 8 },
    { item: 'Sep', value: 9 },
    { item: 'Oct', value: 10 },
    { item: 'Nov', value: 11 },
    { item: 'Dec', value: 12 },
  ],

  weekDayList: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],

  leaveStatusFilter: [
    { item: 'All', value: '' },
    { item: 'New', value: 'New' },
    { item: 'Approved', value: 'Approved' },
    { item: 'Cancelled', value: 'Cancelled' },
    { item: 'Rejected', value: 'Rejected' },
    { item: 'Deleted', value: 'Deleted' },
  ],

  excludeClockIn: [
    'ISS072', // Gui Yic Lung
    'ISS018', // Jay
    'ISS049', // Kelvin
    //'ISS075', // Shahidan
    //'ISS081', // Azratulham
    //'ISS052', // Haziq
  ],

  otStatus: [
    { item: 'New', value: 'NEW' },
    { item: 'Reviewed', value: 'REVIEWED' },
    { item: 'Cancelled', value: 'CANCELLED' },
    { item: 'Rejected', value: 'REJECTED' },
    { item: 'Approved', value: 'APPROVED' },
    { item: 'Processed', value: 'PROCESSED' },
    //{ item: 'Completed', value: 'COMPLETED' },
    { item: 'Replacement Leave', value: 'CONVERTEDRL' },
  ],

  otType: [
    { item: 'OT', value: 'OT' },
    { item: 'Cash', value: 'CO' },
  ],
};
