import Avatar from '../components/Avatar/index';
import UserCard from '../components/Card/UserCard';
import React, { useState, useEffect } from 'react';
import { ListGroup, ListGroupItem, Nav, Navbar, NavItem, NavLink, Popover, PopoverBody } from 'reactstrap';

import bn from '../utils/bemnames';
import { MdExitToApp, MdPerson } from 'react-icons/md';
import { RiLockPasswordFill } from 'react-icons/ri';

import BirthdayCard from '../modules/home/common/BirthdayCard';
import ApprovalCard from '../modules/absences/common/absApprovalCard';
import RecentAbsenceCard from '../modules/absences/common/absRecentCard';

import officeService from '../services/officeService';

const bem = bn.create('header');

const Header = ({ history }) => {
  const [isOpenUserCardPopover, setIsOpenUserCardPopover] = useState(false);
  const [theme, setTheme] = useState('');

  useEffect(() => {
    officeService.getOfficeScheduleLastUpdate().then(res => {
      setTheme(res?.theme?.ClassName || '');
      //${theme?.ClassName || ''}
    });
  }, []);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (isOpenUserCardPopover) {
      rootElement.addEventListener('click', closeAllPopover);
    } else {
      rootElement.removeEventListener('click', closeAllPopover);
    }

    return () => {
      rootElement.removeEventListener('click', closeAllPopover);
    };
  }, [isOpenUserCardPopover]);

  const toggleUserCardPopover = e => {
    e.preventDefault();
    setIsOpenUserCardPopover(!isOpenUserCardPopover);
  };

  const closeAllPopover = e => {
    e.preventDefault();
    setIsOpenUserCardPopover(false);
  };

  const gotoProfile = () => {
    history.push('/profile');
  };

  const gotoChangePwd = () => {
    history.push('/changePassword');
  };

  return (
    <div className={bem.b()}>
      <Navbar light expand className={`isr-header ${theme}`}>
        <Nav navbar className={bem.e('nav')}>
          <NavItem>
            <NavLink id="Popover2" onClick={toggleUserCardPopover}>
              <Avatar
                className="can-click"
                avatarInitial={localStorage.getItem('UserInitial')}
                showArrowDown
                style={{ fontSize: '0.9rem' }}
              />
            </NavLink>
            <Popover
              placement="bottom-start"
              isOpen={isOpenUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light header-user-card">
                <UserCard
                  title={localStorage.getItem('UserName')}
                  //subtitle={localStorage.getItem('JobTitle')}
                  text={localStorage.getItem('UserId') || ''}
                  className="border-light"
                  avatarInitial={localStorage.getItem('UserInitial')}
                >
                  <ListGroup flush>
                    <ListGroupItem onClick={gotoProfile} className="border-light cursor" href="/profile">
                      {' '}
                      <MdPerson /> Profile
                    </ListGroupItem>

                    <ListGroupItem onClick={gotoChangePwd} className="border-light cursor" href="/changePassword">
                      {' '}
                      <RiLockPasswordFill /> Change Password
                    </ListGroupItem>

                    <ListGroupItem tag="a" action className="border-light" href="/logout">
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>

          <span className="logon-user-name cursor" onClick={toggleUserCardPopover}>
            &nbsp;Hi {localStorage.getItem('UserName')}
          </span>
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <NavLink>
              <BirthdayCard />

              {['admin', 'manager', 'lead'].includes(localStorage.getItem('role')) && (
                <ApprovalCard history={history} />
              )}
              <RecentAbsenceCard />
            </NavLink>
          </NavItem>
        </Nav>
        {/*<Notification caller={this} />*/}
      </Navbar>
    </div>
  );
};

export default Header;
