import React, { useEffect, useState } from 'react';
import Page from '../../framework-layout/Page';
import enums from '../../enums';
import officeService from '../../services/officeService';
import './style.scss';
import { Row, Col } from 'reactstrap';
import PageSpinner from '../../components/PageSpinner';
import { getLocation } from '../../utils/getLocation';
import { ClockInOut } from './ClockIn';
import { Announcement } from './Announcement';
import Recognizing from './Recognizing';
import SeatInfo from './SeatInfo';

const auth = require('../../application-center/login-center/lib/authentication');

const Home = ({ history }) => {
  const [lat, setLat] = useState(enums.tgGPS.lat);
  const [lng, setLng] = useState(enums.tgGPS.lng);
  const [distance, setDistance] = useState(0);
  const [location, setLocation] = useState('none');
  const [gpsStatus, setGPSStatus] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [officeMapNewStatus, setMapStatus] = useState(false);
  const [showSeats, setShowSeats] = useState(false);

  useEffect(() => {
    if (!auth.isAuthenticated()) {
      alert('Please login to MyESS!');
    }

    if ('geolocation' in navigator) {
      getLocation('home', processLocation, errorOnLocation);
      loadOffice();
    } else {
      console.log('Not Available');
      setGPSStatus(false);
      setLoading(false);
    }
  }, []);

  const loadOffice = async () => {
    const lastUpdate = await officeService.getOfficeScheduleLastUpdate();

    // TODO Check date
    let today = new Date().getTime();
    let sUpdated = new Date(lastUpdate?.updated || '').getTime();
    if (today - sUpdated < 5 * 24 * 36e5) {
      setMapStatus(true);
    }
  };

  const processLocation = async (lat, lng, dist, dist2) => {
    if (lat !== 0 && lng !== 0) {
      if (dist < 0.5) {
        let user = JSON.parse(localStorage.getItem('User')) ?? null;
        setLocation(user.Office || '11A');
      } else if (dist2 < 0.5 || dist > 10000) {
        setLocation('Client');
      } else {
        setLocation('Home');
      }

      setLat(lat);
      setLng(lng);
      setDistance(dist.toFixed(3) + ' KM');
      setGPSStatus(true);
      setLoading(false);
    } else {
      setGPSStatus(false);
      setLoading(false);
    }
  };

  const errorOnLocation = () => {
    setGPSStatus(false);
    setLoading(false);
  };

  return (
    <Page title="Attendance" className={`home-theme`}>
      <>
        <Row>
          <Col>
            <Announcement history={history} newScheduleIndicator={officeMapNewStatus} showSeatCTA={setShowSeats} />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            {gpsStatus && <ClockInOut lat={lat} lng={lng} initialLocation={location} distance={distance} />}
            {!gpsStatus && isLoading && (
              <>
                Loading...
                <PageSpinner />
              </>
            )}
            {!gpsStatus && !isLoading && 'Please check your GPS Settings'}
          </Col>
          <Col md={6}>
            <Recognizing />
          </Col>
        </Row>

        <SeatInfo showSeats={showSeats} toggle={() => setShowSeats(!showSeats)} />
      </>
    </Page>
  );
};

export default Home;
