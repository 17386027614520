import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Popover, PopoverBody, Badge } from 'reactstrap';
import { MdToday } from 'react-icons/md';
import './style.scss';
import { fetchTodayList } from '../../../../store/absence/absenceActions';
import { setLeaveRequestPanel, setRecentLeavePanel, setDOBPanel } from '../../../../store/portal/portalActions';

const enums = require('../../../../enums');
const helper = require('../../../../utils/helper');

const RecentAbsenceCard = () => {
  const dispatch = useDispatch();
  const { todayList, todayListLoaded } = useSelector(s => s.absence);
  const { isRecentLeaveOpen } = useSelector(s => s.portal);

  const [isPopover, setPopover] = useState(false);
  const [absenceList, setAbsenceList] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!todayListLoaded) {
      dispatch(fetchTodayList());
    }
  }, []);

  useEffect(() => {
    setPopover(isRecentLeaveOpen);
  }, [isRecentLeaveOpen]);

  useEffect(() => {
    let final = {};
    todayList.map(abs => {
      if (final[abs.Date]) {
        final[abs.Date].push(abs);
      } else {
        final[abs.Date] = [];
        final[abs.Date].push(abs);
      }
    });

    Object.keys(final).map(d => {
      final[d] = final[d].sort((a, b) => a.StaffName.localeCompare(b.StaffName));
    });

    const today = helper.ISODate(new Date());

    setAbsenceList(final);
    setCount(final[today]?.length || 0);
  }, [todayList]);

  const togglePopover = useCallback(
    e => {
      e.preventDefault();
      dispatch(setRecentLeavePanel(!isPopover));
      if (!isPopover) {
        dispatch(setDOBPanel(false));
        dispatch(setLeaveRequestPanel(false));
      }
    },
    [isPopover],
  );

  return (
    <div className="recent-card">
      <div>
        <MdToday size={30} id="Popover-Recent" onClick={togglePopover} title="Recent absences" />

        <Badge className="badge" color="success" onClick={togglePopover}>
          {count}
        </Badge>
      </div>

      {isPopover && (
        <Popover
          placement="bottom-end"
          isOpen={isPopover}
          target="Popover-Recent"
          className="p-0 border-0 popover-recent"
          style={{ minWidth: 300 }}
        >
          <PopoverBody className="p-0 border-light">
            <span className="title">Recent absence</span>
            <div className="list">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Leave type</th>
                  </tr>
                </thead>
                {Object.keys(absenceList).length > 0 && (
                  <tbody>
                    {Object.keys(absenceList).map((d, i) => {
                      let rows = [];
                      rows.push(
                        <tr key={(i + 1) * 1000}>
                          <td colSpan={2} className="dateRow">
                            {moment(d).format('DD/MM/YYYY')}
                          </td>
                        </tr>,
                      );

                      absenceList[d].map((ab, i) => {
                        let leaveTypeDisp = enums.leaveTypeMaster
                          .find(t => t.value === ab.LeaveType)
                          ?.item?.split(' ')[0];
                        rows.push(
                          <tr key={i}>
                            <td>{ab.StaffName}</td>
                            <td>
                              {leaveTypeDisp} / {ab.DayType}
                            </td>
                          </tr>,
                        );
                      });

                      return rows;
                    })}
                  </tbody>
                )}

                {Object.keys(absenceList).length === 0 && (
                  <tbody>
                    <tr>
                      <td colSpan={2}>No record</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </PopoverBody>
        </Popover>
      )}
    </div>
  );
};

export default RecentAbsenceCard;
