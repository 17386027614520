import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from '../utils/bemnames';
import pageLogo from '../assets/img/logo/logo.png';

import { GiHamburgerMenu } from 'react-icons/gi';
import { MdKeyboardArrowDown, MdClose } from 'react-icons/md';
import { constructMenuStructure } from '../utils/menuUtils';
import { fetchMenu, setSideMenu } from '../store/portal/portalActions';

//const helper = require('../utils/helper');
//const auth = require('../application-center/login-center/lib/authentication');

const bem = bn.create('sidebar');

const Sidebar = props => {
  const dispatch = useDispatch();
  const { menus, isMenuLoaded, isSideMenuOpen } = useSelector(s => s.portal);

  const defaultMenuId = 1;
  const iconSize = '38px';
  const [structuredMenus, setStructuredMenus] = useState([]);

  const [isModuleOpen, setModuleOpen] = useState({});
  const [menuPath, setMenuPath] = useState([]);

  useEffect(() => {
    if (!isMenuLoaded) {
      dispatch(fetchMenu());
    }
  }, []);

  useEffect(() => {
    setStructuredMenus(constructMenuStructure(menus));
  }, [menus]);

  useEffect(() => {
    if (structuredMenus.length > 0) {
      constructMenusSetting();
    }
  }, [structuredMenus, props.history]);

  useEffect(() => {
    if (document.querySelector('.menu-open')) {
      if (!isSideMenuOpen) {
        document.querySelector('.menu-open').style.display = 'inline';
        document.querySelector('.menu-close').style.display = 'none';
        document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
      } else {
        document.querySelector('.menu-open').style.display = 'none';
        document.querySelector('.menu-close').style.display = 'inline';
        document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
      }
    }
  }, [isSideMenuOpen]);

  const constructMenusSetting = useCallback(() => {
    let pathName = '';
    if (window.location.pathname === '/') {
      props.history.push('/home');
      return;
    } else {
      pathName = window.location.pathname.substring(1).toLowerCase().trim();
    }

    let currentItem = {};
    structuredMenus.map(mMaster => {
      if (mMaster.MenuCode.toLowerCase().trim() === pathName) currentItem = mMaster;
      else {
        mMaster.children.map(mL1 => {
          if (mL1.MenuCode.toLowerCase().trim() === pathName) currentItem = mL1;
          else {
            mL1.children.map(mL2 => {
              if (mL2.MenuCode.toLowerCase().trim() === pathName) currentItem = mL2;
            });
          }
        });
      }
    });
    const menuPath = constructMenuPath(currentItem);

    if (menuPath.menuPath.length > 1) {
      let selectedMenuId = defaultMenuId;
      if (menuPath.level1Item) {
        selectedMenuId = menuPath.level1Item.RecId;
      }
      if (menuPath.masterItem) {
        selectedMenuId = menuPath.masterItem.RecId;
      }
      setModuleOpen({
        ...isModuleOpen,
        [selectedMenuId]: true,
      });
    }

    setMenuPath(menuPath.menuPath);
  }, [menus, structuredMenus, props.history]);

  const constructMenuPath = useCallback(
    currentItem => {
      let masterItem = {};
      let level1Item = {};
      let menuPath = [];
      if (currentItem?.level === 1) {
        // First level menu
        menuPath.push(currentItem.MenuLabel);
      } else if (currentItem?.level === 2) {
        // Second level menu
        masterItem = menus.filter(m => m.RecId === currentItem.ParentId)[0];

        menuPath.push(masterItem.MenuLabel);
        menuPath.push(currentItem.MenuLabel);
      } else {
        level1Item = menus.filter(m => m.RecId === currentItem.ParentId)[0];
        masterItem = menus.filter(m => m.RecId === level1Item.ParentId)[0];

        menuPath.push(masterItem.MenuLabel);
        menuPath.push(level1Item.MenuLabel);
        menuPath.push(currentItem.MenuLabel);
      }

      return {
        menuPath: menuPath,
        masterItem: masterItem,
        level1Item: level1Item,
      };
    },
    [menus],
  );

  const handleFolderClick = useCallback(
    recId => {
      setModuleOpen({
        ...isModuleOpen,
        [recId]: isModuleOpen[recId] ? false : true,
      });
    },
    [isModuleOpen],
  );

  const handleSidebarControlButton = useCallback(
    (e, curItem) => {
      e.preventDefault();
      e.stopPropagation();

      let menuPath = {};
      if (curItem !== 'none') {
        menuPath = constructMenuPath(curItem);
        setMenuPath(menuPath.menuPath);
      }

      dispatch(setSideMenu(!isSideMenuOpen));
    },
    [isSideMenuOpen],
  );

  return (
    <aside className={bem.b()}>
      {structuredMenus.length === 0 && <></>}
      {structuredMenus.length > 0 && (
        <div className={bem.e('content')}>
          <Nav vertical>
            <Nav navbar className="menu-toggle">
              <div onClick={e => handleSidebarControlButton(e, 'none')}>
                <GiHamburgerMenu
                  size={parseInt(iconSize)}
                  style={{ cursor: 'pointer' }}
                  className="menu-open pngIcon"
                />
                <MdClose
                  size={parseInt(iconSize)}
                  style={{ cursor: 'pointer', display: 'none' }}
                  className="menu-close pngIcon"
                />
              </div>
            </Nav>

            {!isSideMenuOpen && (
              <>
                <div className="vertical-bar" onClick={e => handleSidebarControlButton(e, 'none')}>
                  {menuPath.map((p, pIdx) => {
                    return (
                      <div key={pIdx}>
                        {pIdx === 0 && <span className="gap">&nbsp;</span>}
                        <span>{p}</span>
                        {pIdx < menuPath.length - 1 && <span className="gap">&gt;</span>}
                      </div>
                    );
                  })}
                </div>
                <img src={pageLogo} className="logo-bar" />
              </>
            )}

            {isSideMenuOpen &&
              structuredMenus.map((mMaster, mIdx) => {
                // Menu start here ---------------------------------------
                if (mMaster.MenuOpenMethod === 'C') {
                  // Master menu list ------------------------------------
                  return (
                    <NavItem
                      key={mIdx}
                      className={`${bem.e('nav-item')}`}
                      title={mMaster.MenuLabel}
                      onClick={e => handleSidebarControlButton(e, mMaster)}
                    >
                      <BSNavLink
                        id={`navItem-${mMaster.MenuCode}`}
                        tag={NavLink}
                        to={`/${mMaster.MenuCode}`}
                        activeClassName="active"
                        exact={false}
                        onClick={() => console.log('c')}
                      >
                        <span className="">{mMaster.MenuLabel}</span>
                      </BSNavLink>
                    </NavItem>
                  );
                } else if (mMaster.MenuOpenMethod === 'F' && mMaster.children.length > 0) {
                  // Master folder list ----------------------------------
                  return (
                    <div key={`setup${mIdx}`}>
                      <NavItem
                        className={`${bem.e('nav-item')} folder master`}
                        title={mMaster.MenuLabel}
                        onClick={() => handleFolderClick(mMaster.RecId)}
                      >
                        <BSNavLink className={bem.e('nav-item-collapse')}>
                          <div className="d-flex">
                            <span className=" align-self-start">{mMaster.MenuLabel}</span>
                          </div>
                        </BSNavLink>
                      </NavItem>
                      <Collapse isOpen={isModuleOpen[mMaster.RecId]}>
                        {mMaster.children.map((mL1, l1Idx) => {
                          if (mL1.MenuOpenMethod === 'C') {
                            // Level 1 link list ----------------------------------
                            return (
                              <NavItem
                                key={`sub${l1Idx})`}
                                className={`${bem.e('nav-item')} lvl1`}
                                title={mL1.MenuLabel}
                                onClick={e => handleSidebarControlButton(e, mL1)}
                              >
                                <BSNavLink
                                  id={`navItem-${mL1.MenuCode}-${l1Idx}`}
                                  tag={NavLink}
                                  to={`/${mL1.MenuCode}`}
                                  activeClassName="active"
                                  exact={false}
                                >
                                  <span className="">{mL1.MenuLabel}</span>
                                </BSNavLink>
                              </NavItem>
                            );
                          } else if (mL1.MenuOpenMethod === 'F' && mL1.children.length > 0) {
                            // Level 1 folder list ----------------------------------
                            return (
                              <div key={`${mIdx}${l1Idx}`}>
                                <NavItem
                                  className={`${bem.e('nav-item')} folder lvl1`}
                                  title={mL1.MenuLabel}
                                  onClick={() => handleFolderClick(mL1.RecId)}
                                >
                                  <BSNavLink className={bem.e('nav-item-collapse')}>
                                    <div className="d-flex">
                                      <span className=" align-self-start">{mL1.MenuLabel}</span>
                                    </div>
                                    <MdKeyboardArrowDown
                                      className={bem.e('nav-item-icon')}
                                      style={{
                                        padding: 0,
                                        transform: isModuleOpen[mL1.RecId] ? 'rotate(0deg)' : 'rotate(-90deg)',
                                        transitionDuration: '0.3s',
                                        transitionProperty: 'transform',
                                      }}
                                    />
                                  </BSNavLink>
                                </NavItem>
                                <Collapse isOpen={isModuleOpen[mL1.RecId]}>
                                  {mL1.children.map((mL2, l2idx) => {
                                    return (
                                      <NavItem
                                        key={`sub${l2idx})`}
                                        className={`${bem.e('nav-item')} lvl2`}
                                        title={mL2.MenuLabel}
                                        onClick={e => handleSidebarControlButton(e, mL2)}
                                      >
                                        <BSNavLink
                                          id={`navItem-${mL2.MenuCode}-${l2idx}`}
                                          tag={NavLink}
                                          to={`/${mL2.MenuCode}`}
                                          activeClassName="active"
                                          exact={false}
                                        >
                                          <span className="">{mL2.MenuLabel}</span>
                                        </BSNavLink>
                                      </NavItem>
                                    );
                                  })}
                                </Collapse>
                              </div>
                            );
                          }
                        })}
                      </Collapse>
                    </div>
                  );
                }
              })}
          </Nav>
        </div>
      )}
    </aside>
  );
};

export default Sidebar;
