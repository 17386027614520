import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from '../utils/propTypes';
import bn from '../utils/bemnames';
import Typography from '../components/Typography';

const bem = bn.create('page');
const auth = require('../application-center/login-center/lib/authentication');
const helper = require('../utils/helper');

const Page = props => {
  const {
    title,
    //breadcrumbs,
    tag: Tag,
    className,
    children,
    requiredLogin,
    ...restProps
  } = props;

  const classes = bem.b('px-3', className);

  if (requiredLogin && !auth.isAuthenticated()) {
    console.log('Not authenticated, redirect to landing page');
    return <Redirect to={helper.getLandingPage()} />;
  }

  return (
    <Tag className={`${classes}`} {...restProps}>
      <div className={bem.e('header')}>
        {title && typeof title === 'string' ? (
          <Typography type="h1" className={bem.e('title')}>
            {title}
          </Typography>
        ) : (
          title
        )}
      </div>
      {children}
    </Tag>
  );
};

Page.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  requiredLogin: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
};

Page.defaultProps = {
  tag: 'div',
  title: '',
  requiredLogin: true,
};

export default Page;
