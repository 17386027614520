import React from 'react';
import PropTypes from '../../utils/propTypes';
import classNames from 'classnames';
import './avatar.scss';

const Avatar = ({
  rounded,
  circle,
  src,
  avatarInitial,
  size,
  tag: Tag,
  className,
  style,
  //showArrowDown,
  ...restProps
}) => {
  const classes = classNames({ 'rounded-circle': circle, rounded }, className);

  return (
    <div>
      {src !== '' && (
        <Tag src={src} style={{ width: size, height: size, ...style }} className={classes} {...restProps} />
      )}
      {src === '' && (
        <div className="avatarInitial" style={{ width: size, height: size, ...style }}>
          {avatarInitial}
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  tag: PropTypes.component,
  rounded: PropTypes.bool,
  circle: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  avatarInitial: PropTypes.string,
  style: PropTypes.object,
  showArrowDown: PropTypes.bool,
};

Avatar.defaultProps = {
  tag: 'img',
  rounded: false,
  circle: true,
  size: 28,
  src: '',
  avatarInitial: '',
  style: {},
  showArrowDown: false,
};

export default Avatar;
