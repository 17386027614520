import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormGroup, Input } from 'reactstrap';

import PageSpinner from '../../components/PageSpinner';
import { RiUserLine, RiLockPasswordLine } from 'react-icons/ri';

//const initData = require('./lib/dataInitialization');
const auth = require('./lib/authentication');
//const ux = require('../ux-tracking-center');

class NormalLoginForm extends React.Component {
  state = {
    username: '',
    password: '',
    errorMessage: '',
    loginProcess: false,
  };

  handleUsernameChange = event => {
    this.setState({ username: event.target.value });
  };

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();

    if (!this.state.username && !this.state.password) {
      this.setState({ errorMessage: 'Please fill in Username and Password!' });
    } else if (!this.state.username) {
      this.setState({ errorMessage: 'Please fill in Username!' });
    } else if (!this.state.password) {
      this.setState({ errorMessage: 'Please fill in Password!' });
    }
    if (this.state.username && this.state.password) {
      this.setState({ loginProcess: true, errorMessage: '' });
      auth
        .doLogin({
          username: this.state.username,
          password: this.state.password,
        })
        .then(result => {
          if (result.status) {
            console.log('Login success', result);
            Promise.all([
              //initData.initParameters(),
              //ux.logAuthenticated(result.isSSO),
              // if more data need to init, append here
            ]).then(() => {
              if (result.requiredChangePwd) {
                this.props.history.push('/changePassword?new=1');
              } else {
                this.props.history.push('/home');
              }
            });
          } else {
            console.log('Login failed');
            this.setState({
              errorMessage: result.message,
              loginProcess: false,
            });
          }
        });
    }
  };

  render() {
    const { usernameInputProps, passwordInputProps, children } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {this.state.loginProcess && <PageSpinner />}

        {this.state.errorMessage && (
          <div className="login-error">
            <span>Error: </span>
            {this.state.errorMessage}
          </div>
        )}

        <FormGroup>
          <div className="login-field-wrapper">
            <RiUserLine size={32} />
            <Input {...usernameInputProps} onChange={this.handleUsernameChange} />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="login-field-wrapper">
            <RiLockPasswordLine size={32} />
            <Input {...passwordInputProps} onChange={this.handlePasswordChange} />
          </div>
        </FormGroup>
        <button size="lg" className="login-button" block="true" onClick={this.handleSubmit}>
          Sign In
        </button>

        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_LOGOUT = 'LOGOUT';
export const STATE_SIGNUP = 'SIGNUP';

NormalLoginForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_LOGOUT, STATE_SIGNUP]).isRequired,
  usernameInputProps: PropTypes.object,
  passwordInputProps: PropTypes.object,
  ssoUser: PropTypes.string,
};

NormalLoginForm.defaultProps = {
  authState: 'LOGIN',

  usernameInputProps: {
    id: 'username',
    type: 'text',
    placeholder: 'Username ISS000',
    required: 'required',
  },
  passwordInputProps: {
    id: 'password',
    type: 'password',
    placeholder: 'Password',
    required: 'required',
  },
  ssoUser: '',
  fromLogout: '',
};

export default NormalLoginForm;
