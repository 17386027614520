import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import Sidebar from './Sidebar';
import { withRouter } from 'react-router-dom';
// notification
import { MdInfo } from 'react-icons/md';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';
import { resetPopover, setSideMenu } from '../store/portal/portalActions';
import '../utils/shortcutKeys';

const MainLayout = props => {
  const dispatch = useDispatch();
  const { isSideMenuOpen } = useSelector(s => s.portal);
  const notificationSystemRef = useRef(null);

  const notification = useCallback((message, type, children, delay) => {
    if (!delay) delay = 0;

    setTimeout(() => {
      if (!notificationSystemRef.current) {
        return;
      }

      if (!type) type = 'success';
      if (!children) children = '';

      notificationSystemRef.current.addNotification({
        title: <MdInfo />,
        message: message,
        level: type,
        children: children,
      });
    }, delay);
  }, []);

  const handleContentClick = () => {
    if (isSideMenuOpen) {
      dispatch(setSideMenu(false));
    }
  };

  const handleContentWrapperClick = () => {
    dispatch(resetPopover());
    dispatch(setSideMenu(false));
  };

  const redirect = url => {
    props.history.push(url);
  };

  useEffect(() => {
    window.message.notification = notification;
    window.redirect = redirect;
  }, [props.breakpoint]);

  return (
    <main className="cr-app">
      <Sidebar history={props.history} location={props.location} />
      <Content fluid onClick={handleContentClick}>
        <Header history={props.history} location={props.location} />
        <div onClick={handleContentWrapperClick}>{props.children}</div>
        <Footer />
      </Content>

      <NotificationSystem dismissible={false} ref={notificationSystemRef} style={NOTIFICATION_SYSTEM_STYLE} />
    </main>
  );
};

export default withRouter(MainLayout);
