import enums from '../enums';

export const getLocation = async (source, processLocation = () => null, onLocationError = () => null) => {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(pos => {
      console.log(pos);
      let lat = pos.coords.latitude;
      let lng = pos.coords.longitude;
      let dist = getDistanceFromLatLonInKm(enums.tgGPS.lat, enums.tgGPS.lng, lat, lng);
      let iwkDist = getDistanceFromLatLonInKm(enums.IWKGPS.lat, enums.IWKGPS.lng, lat, lng);

      let locObject = {
        source: source,
        lat: lat,
        lng: lng,
        dist: dist,
        time: new Date().getTime(),
        date: new Date().toString(),
      };
      localStorage.setItem('xyz', JSON.stringify(locObject));
      processLocation(lat, lng, dist, iwkDist);
    }, onLocationError);
  }
};

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

export const deg2rad = deg => {
  return deg * (Math.PI / 180);
};

const LocBlankObj = {
  lat: 0,
  lng: 0,
  dist: 99999,
  time: 0,
  date: 'error',
  source: 'null',
};

export const checkLastLocation = async (source, force = false) => {
  let xyz = JSON.parse(localStorage.getItem('xyz')) || LocBlankObj;
  if (xyz.time < new Date().getTime() - 2 * 36e5 || force) {
    await getLocation(source);
    xyz = JSON.parse(localStorage.getItem('xyz')) || LocBlankObj;
  }
  return xyz;
};

export const getLastLocation = () => {
  return JSON.parse(localStorage.getItem('xyz')) || LocBlankObj;
};
