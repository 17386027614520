import React, { Suspense } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
const SeatCard = React.lazy(() => import('../../profiles/common/SeatCard'));
const WFOScheduleList = React.lazy(() => import('../../profiles/common/WFOList'));
import styles from './style.module.scss';

const SeatInfo = ({ showSeats = false, toggle }) => {
  return (
    <Modal isOpen={showSeats} toggle={toggle} className={styles['modal-seat']}>
      <ModalHeader className={styles['modal_header']} toggle={toggle}>
        Seat Information
      </ModalHeader>
      <ModalBody>
        <Suspense fallback={null}>
          <SeatCard />
          <WFOScheduleList />
        </Suspense>
      </ModalBody>
    </Modal>
  );
};

export default SeatInfo;
