import axios from 'axios';
import { getAPIHost } from '../utils/helper';
import '../utils/axios-interceptor';

export default {
  async getMenu() {
    const records = await axios.get(`${getAPIHost()}/api/index.php/menus/list`);
    return records.data.result || [];
  },
};
