import { lazy } from 'react';
// Profile

// General
const Profile = lazy(() => import('./general/profile'));
const ChangePassword = lazy(() => import('./general/changePassword'));
const Schedule = lazy(() => import('./general/schedule'));
const Attendance = lazy(() => import('./general/attendance'));
const RTKSubmit = lazy(() => import('./general/rtkSubmit'));
const MyRTK = lazy(() => import('./general/myRtkResult'));
const MyReviews = lazy(() => import('./general/myReviews'));

// Admin
const StaffMaint = lazy(() => import('./admin/staffMaint'));
const OfficeSchedule = lazy(() => import('./admin/officeSchedule'));
const SeatManagement = lazy(() => import('./admin/seatManagement'));
const ProfileNameList = lazy(() => import('./admin/profileNameList'));
const StaffDistance = lazy(() => import('./admin/staffDistance'));
const StaffRouteDistance = lazy(() => import('./admin/staffRouteDistance'));

export const routes = [
  // General
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/changePassword',
    component: ChangePassword,
  },
  {
    path: '/schedule',
    component: Schedule,
  },
  {
    path: '/attendance',
    component: Attendance,
  },
  {
    path: '/rtkSubmit',
    component: RTKSubmit,
  },
  {
    path: '/myRTK',
    component: MyRTK,
  },
  {
    path: '/myReviews',
    component: MyReviews,
  },

  // Admin
  {
    path: '/staffMaint',
    component: StaffMaint,
  },
  {
    path: '/officeSchedule',
    component: OfficeSchedule,
  },
  {
    path: '/seatManagement',
    component: SeatManagement,
  },
  {
    path: '/namelist',
    component: ProfileNameList,
  },
  {
    path: '/staffDistance',
    component: StaffDistance,
  },
  {
    path: '/staffRouteDistance',
    component: StaffRouteDistance,
  },
];
