import axios from 'axios';
import { getAPIHost } from '../utils/helper';
import '../utils/axios-interceptor';

export default {
  async getOfficeList() {
    const records = await axios.get(`${getAPIHost()}/api/index.php/staff/officeList`);
    return records.data.result || [];
  },
  async getDepartmentList() {
    const records = await axios.get(`${getAPIHost()}/api/index.php/staff/departmentList`);
    return records.data.result || [];
  },
  async getGuideURL() {
    const records = await axios.get(`${getAPIHost()}/api/index.php/office/guideURL`);
    return records.data.result || [];
  },
  async getDocumentationsURL() {
    let records = await axios.get(`${getAPIHost()}/api/index.php/office/documentations`);
    return records.data.result || [];
  },
  async getOfficeScheduleLastUpdate() {
    const records = await axios.get(`${getAPIHost()}/api/index.php/office/officeLastUpdate`);
    return records.data.result || [];
  },
  async getOfficeMap(office) {
    let records = await axios.get(`${getAPIHost()}/api/index.php/office/office?office=${office}`);
    return records.data.result || [];
  },
};
