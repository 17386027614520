import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  requestList: [],
  requestListLoaded: false,
  todayList: [],
  todayListLoaded: false,
};

export const absenceSlice = createSlice({
  name: 'absence',
  initialState,
  reducers: {
    setRequestList: (state, action) => {
      state.requestList = action.payload;
      state.requestListLoaded = true;
    },
    setTodayList: (state, action) => {
      state.todayList = action.payload;
      state.todayListLoaded = true;
    },
  },
});
