import axios from 'axios';
//const version = require('../version');

//console.log("PROCESS", version)

export const getWebVersion = () => '1.0.0';
export const getAPIVersion = () => localStorage.getItem('appVersion') || '';
export const getParameters = () => JSON.parse(localStorage.getItem('Parameters') || '{}');

export const getUserId = () => localStorage.getItem('UserId') || '';
export const getUserRole = () => localStorage.getItem('UserRole') || 'guest';

export const getEmplId = () => {
  console.log('Depreciated: helper.getEmpId');
  return getUserId();
};
export const getStorelId = () => localStorage.getItem('StoreId') || '';
export const getOutletId = () => localStorage.getItem('OutletId') || '';
export const getUserGroup = () => {
  console.log('Depreciated: helper.getUserGroup');
  return getUserRole();
};

export const getAPIHost = () => process.env.REACT_APP_API || '//175.136.236.153:8106';

export const getLandingPage = () => process.env.REACT_APP_LANDING_PAGE;

export const apiHeaders = () => {
  console.warn('Deprecated: helper.apiHeaders');
  return {
    Authorization: 'Bearer ' + localStorage.getItem('appToken'),
    'Content-Type': 'application/json',
  };
};

export const getEnvironment = type => {
  if (type === '' || !type) {
    return process.env.REACT_APP_ENV === 'PROD' ? '' : process.env.REACT_APP_ENV;
  } else {
    let name = process.env.REACT_APP_ENV === 'PROD' ? '' : process.env.REACT_APP_ENV;
    if (name === 'ISS') name = 'issdev';
    return name.toLowerCase();
  }
};

export const isLocal = () => process.env.REACT_APP_ENV === 'LOCAL' || process.env.REACT_APP_ENV === 'ISS';
export const isDEV = () => process.env.REACT_APP_ENV === 'DEV';
export const isQA = () => process.env.REACT_APP_ENV === 'QA';
export const isProd = () => process.env.REACT_APP_ENV === 'PROD';

export const checkAPIVersion = () => {
  let check = false;
  let currentTime = parseInt(new Date().getTime() / 1000);
  let lastCheck = parseInt(localStorage.getItem('versionLastCheck') || 0);

  if (lastCheck === 0) {
    localStorage.setItem('versionLastCheck', currentTime);
  } else {
    if (currentTime - 3600 > lastCheck) {
      check = true;
    }
  }

  if (check) {
    try {
      console.log('check version');
      axios({
        url: getAPIHost() + '/getVersion',
        method: 'GET',
        headers: apiHeaders(),
      }).then(async res => {
        let data = res.data;
        console.log(data);

        if (data.result.version !== localStorage.getItem('appVersion')) {
          alert(`New version of app detected. Please re-login to ${process.env.REACT_APP_NAME}!`);
        }

        localStorage.setItem('versionLastCheck', currentTime);
      });
    } catch (r) {
      console.log('Check version ');
      console.log(r);
    }
  }
};

export const checkVersionScheduller = () => {
  let checkCount = parseInt(localStorage.getItem('versionCheckCount') || 0);
  localStorage.setItem('versionCheckCount', checkCount++);

  checkAPIVersion();

  setTimeout(() => checkVersionScheduller(), 3600 * 1000);
};

export const getISOLocalString = (date = new Date()) => {
  let tzo = -date.getTimezoneOffset();

  if (tzo === 0) {
    return date.toISOString();
  } else {
    let dif = tzo >= 0 ? '+' : '-';
    let pad = function (num, digits = 2) {
      return String(num).padStart(digits, '0');
    };

    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      '.' +
      pad(date.getMilliseconds(), 3) +
      dif +
      pad(tzo / 60) +
      ':' +
      pad(tzo % 60)
    );
  }
};

export const formatDateString = (date = new Date()) => {
  let tzo = -date.getTimezoneOffset();

  if (tzo === 0) {
    return date.toISOString();
  } else {
    let pad = function (num, digits = 2) {
      return String(num).padStart(digits, '0');
    };

    return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());
  }
};

export const formatNumber = (number, decimal, prefix, postfix) => {
  if (isNaN(number)) number = 0;
  number = parseFloat(number);
  if (!prefix) prefix = '';
  if (!postfix) postfix = '';
  return (
    prefix +
    number.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    }) +
    postfix
  );
};

export const formatNumber2 = (number, digit) => {
  if (isNaN(number)) number = 0;
  let digitStr = '';
  for (let i = 1; i < digit; i++) {
    digitStr = digitStr + '0';
  }
  var formattedNumber = (digitStr + number).slice(-digit);
  return formattedNumber;
};

export const getMonthName = number => {
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[number - 1];
};

export const getDayName = number => {
  let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return days[number];
};

export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const getQSParam = key => {
  let qs = new URLSearchParams(window.location.search);
  return qs.get(key);
};

export const shortNumber = (n, d) => {
  if (n < 1000) return parseFloat(n).toFixed(d);
  else if (n < 1000000) return parseFloat(n / 1000).toFixed(d) + 'K';
  else return parseFloat(n / 1000000).toFixed(d) + 'M';
};

export const getConditionColor = (n, c) => {
  if (!c) {
    c = {
      0: '#FF7285',
      80: '#ffda83',
      100: '#7CD89C',
    };
  }
  let ret = '#FF7285';
  for (let i in c) {
    if (n >= parseInt(i)) ret = c[i];
  }
  return ret;
};

export const getConditionColorClass = (n, c) => {
  if (!c) {
    c = {
      0: 'low',
      80: 'high',
      100: 'fulfill',
    };
  }
  let ret = 'low';
  for (let i in c) {
    if (n >= parseInt(i)) ret = c[i];
  }
  return ret;
};

export const cmp = function (a, b, direction = 'asc') {
  if (direction !== 'asc') {
    if (a < b) return 1;
    if (a > b) return -1;
  } //Descending order
  else {
    if (a > b) return 1;
    if (a < b) return -1;
  }
  return 0;
};

export const sort = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.key || !b.key) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key] || '';
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key] || '';

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

export const dateYYYYMMDD = str => {
  let s = str.substr(0, 10).split('-');
  return new Date(s[0], parseInt(s[1]) - 1, s[2], 0, 0, 0, 0);
};

export const ISODate = d => {
  return d.getFullYear() + '-' + zeroPad(d.getMonth() + 1, 2) + '-' + zeroPad(d.getDate(), 2);
};

export const ISODateTime = d => {
  return (
    d.getFullYear() +
    '-' +
    zeroPad(d.getMonth() + 1, 2) +
    '-' +
    zeroPad(d.getDate(), 2) +
    ' ' +
    zeroPad(d.getHours(), 2) + // H
    ':' +
    zeroPad(d.getMinutes(), 2) + // i
    ':' +
    zeroPad(d.getSeconds(), 2) // s
  );
};

export const formatDate = str => {
  // from YYYY-MM-DD
  if (str === '' || str === null || str === undefined) return '';
  else if (str.indexOf('T') > 0 || str.indexOf(' ') > 0) {
    // for date with timezone
    let d = new Date(str);
    return zeroPad(d.getMonth() + 1, 2) + '/' + zeroPad(d.getDate(), 2) + '/' + d.getFullYear(); // return mm/dd/yyyy
  } else {
    // for date only
    let s = str.split('-');
    return s[1] + '/' + s[2] + '/' + s[0]; // return mm/dd/yyyy
  }
};

export const arrayRemoveNull = array => {
  let newArray = array.filter(item => {
    return item;
  });
  return newArray;
};

export const isEmpty = obj => {
  for (var prop in obj) {
    if (obj[prop]) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const phoneNumberFormat = str => {
  if (str.indexOf('-') === 3 && str.indexOf('-', 4) === 7) return str;
  else {
    var cleaned = ('' + str).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    return null;
  }
};

export const updateOpenOrderEnum = (enums, buckets) => {
  let priority = 0;
  let parentText = '';
  if (buckets.length > 0) {
    let newEnums = {};
    buckets.map(b => {
      if (b.menuType === 'BUCKET') {
        parentText = b.menuLabel;
        priority = b.sequence;
      }

      if (b.menuType === 'SUBBUCKET') {
        newEnums[b.menuIcon] = {
          // menuIcon is enum key name
          value: parseInt(b.menuCode),
          code: 'd' + parseInt(b.menuCode),
          label: b.menuLabel,
          action: b.menuOpenMethod === 'A',
          parentId: b.parentId,
          priority: priority,
          priorityTxt: parentText,
        };
      }
    });
    enums.openOrderBucket = newEnums;
  }

  console.log(enums.openOrderBucket);
};

export const removeSpecialChar = text => {
  return text.replace(/[^a-zA-Z0-9]/g, '');
};

export const catchServer = res => {
  if (res?.server) {
    console.log('APP Server', res.server);
    localStorage.setItem('APP_SERVER', res.server);
  }
  if (!localStorage.getItem('WEB_SERVER') || localStorage.getItem('WEB_SERVER') !== process.env.REACT_APP_SERVER) {
    localStorage.setItem('WEB_SERVER', process.env.REACT_APP_SERVER);
  }
};

export const getYearList = (maxYear, pastYear = 2020) => {
  let yearList = [];
  for (let yr = pastYear; yr <= maxYear; yr++) {
    yearList.push(yr);
  }
  return yearList;
};
