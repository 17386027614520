import { absenceSlice } from './absenceSlice';
import absenceService from '../../services/absenceService';
//const enums = require('../../enums');

export const sliceActions = absenceSlice.actions;

export const fetchRequestList = () => {
  return async dispatch => {
    let records = await absenceService.getRequestList();
    dispatch(sliceActions.setRequestList(records));
  };
};

export const fetchTodayList = () => {
  return async dispatch => {
    let records = await absenceService.getTodayList();
    dispatch(sliceActions.setTodayList(records));
  };
};
