import React from 'react';
import PropTypes from '../../utils/propTypes';

import classNames from 'classnames';

import { Card, CardTitle, CardSubtitle, CardText, CardBody } from 'reactstrap';

import Avatar from '../Avatar/index';

const UserCard = ({ avatar, avatarInitial, avatarSize, title, subtitle, text, children, className, ...restProps }) => {
  const classes = classNames('bg-gradient-theme', className);

  return (
    <Card inverse className={classes} {...restProps}>
      <CardBody className="d-flex justify-content-center align-items-center flex-column">
        <Avatar
          src={avatar}
          size={avatarSize}
          className="mb-2"
          avatarInitial={avatarInitial}
          style={{ fontSize: '2.5rem' }}
        />
        <CardTitle>
          {text} - {title}
        </CardTitle>
        <CardSubtitle>{subtitle}</CardSubtitle>
        <CardText>
          <small></small>
        </CardText>
      </CardBody>
      {children}
    </Card>
  );
};

UserCard.propTypes = {
  avatar: PropTypes.string,
  avatarInitial: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

UserCard.defaultProps = {
  avatarSize: 80,
};

export default UserCard;
